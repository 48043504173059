import { render, staticRenderFns } from "./FesREventDetailComponent.vue?vue&type=template&id=4aee3008&"
import script from "./FesREventDetailComponent.vue?vue&type=script&lang=js&"
export * from "./FesREventDetailComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports