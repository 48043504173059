<template>
    <div>
        <v-app id="inspire">
            <v-card
            class="indigo darken-2 pa-6"
            tile
            >
            <v-row>
                <v-col
                cols="12"
                md="2"
                >
                    <leftMenu></leftMenu>
                </v-col>
                <v-col
                cols="12"
                md="8"
                >
                    <v-card elevation="12" class="pa-2">
                        <h2 class="pa-10 pb-1">イベント作成</h2>
                        <v-stepper v-model="e1" class="ma-4">
                            <v-stepper-header>
                                <v-stepper-step
                                    :complete="e1 > 1"
                                    step="1"
                                >イベント作成</v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step
                                    :complete="e1 > 2"
                                    step="2"
                                >ローテ種別設定</v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step
                                    :complete="e1 > 3"
                                    step="3"
                                >ローテ個別設定</v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step
                                    :complete="e1 > 4"
                                    step="4"
                                >時間設定</v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step step="5">メンバー設定</v-stepper-step>
                            </v-stepper-header>
                            <v-stepper-items>
                                <v-stepper-content step="2">
                                    <v-form 
                                        ref="form1"
                                        v-model="valid"
                                        lazy-validation
                                        >
                                        <v-row>
                                            <v-col cols="12" class="pa-6" v-for="i in 8" v-bind:key="i">
                                                <v-card class="pa-4 elevation-4" >
                                                    <v-card-title class="text-h5">
                                                    ローテ種別{{i}}
                                                    </v-card-title>
                                                    <v-card-subtitle>ローテ名及び講習有無設定{{i}}</v-card-subtitle>
                                                    <v-text-field
                                                        v-model="$data['rotename'+i]"
                                                        :counter="20"
                                                        :rules="nameRules"
                                                        label="ローテーション名"
                                                        required
                                                    ></v-text-field>
                                                    <v-switch
                                                        v-model="$data['rotetf'+i]"
                                                        label="講習有無"
                                                    ></v-switch>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                    <v-btn
                                    color="primary"
                                    @click="addCate"
                                    >Next</v-btn>
                                </v-stepper-content>
                                <v-stepper-content step="3">
                                    <v-form 
                                        ref="form2"
                                        v-model="valid"
                                        lazy-validation
                                        >
                                        <v-row>
                                            <v-col cols="12" class="pa-6" v-for="ii in forday" v-bind:key="ii">
                                                <v-card class="pa-4 elevation-4" >
                                                    <v-card-title class="text-h5">
                                                    Day{{ii}}
                                                    </v-card-title>
                                                    <v-card-subtitle>{{ii}}日目におけるローテ数設定</v-card-subtitle>
                                                    <div v-for="(iii,index) in gottenCateNum"  v-bind:key="index">
                                                        <v-subheader>{{gottenCate[index]}}</v-subheader>
                                                        <v-slider
                                                        v-model="catenum[ii-1][index]"
                                                        thumb-label="always"
                                                        min="0"
                                                        max="20"
                                                        ticks="always"
                                                        ></v-slider> 
                                                    </div>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                    <v-btn
                                    color="primary"
                                    @click="addDay"
                                    >
                                    Next
                                    </v-btn>
                                    <v-btn 
                                    text  
                                    @click="e1 = 2"
                                    >
                                    Cancel
                                    </v-btn>
                                </v-stepper-content>
                                <v-stepper-content step="4">
                                    <v-form 
                                        ref="form3"
                                        v-model="valid"
                                        lazy-validation
                                        >
                                        <div v-for="(time,index) in 20" v-bind:key="index">
                                            <v-text-field
                                                v-model="times[index]"
                                                :counter="20"
                                                :rules="nameRules"
                                                label="時間名"
                                            ></v-text-field>
                                        </div>
                                    </v-form>
                                    <v-btn
                                    color="primary"
                                    @click="addTime"
                                    >
                                    Next
                                    </v-btn>
                                    <v-btn 
                                    text  
                                    @click="e1=3"
                                    >
                                    Cancel
                                    </v-btn>
                                </v-stepper-content>
                                <v-stepper-content step="5">
                                    <v-form 
                                        ref="form4"
                                        v-model="valid"
                                        lazy-validation
                                    >
                                        <v-checkbox
                                        v-model="memberyear"
                                        label="2018"
                                        value="2018"
                                        ></v-checkbox>
                                        <v-checkbox
                                        v-model="memberyear"
                                        label="2019"
                                        value="2019"
                                        ></v-checkbox>
                                        <v-checkbox
                                        v-model="memberyear"
                                        label="2020"
                                        value="2020"
                                        ></v-checkbox>
                                        <v-checkbox
                                        v-model="memberyear"
                                        label="2021"
                                        value="2021"
                                        ></v-checkbox>
                                        <v-checkbox
                                        v-model="memberyear"
                                        label="2022"
                                        value="2022"
                                        ></v-checkbox>
                                        <v-checkbox
                                        v-model="memberyear"
                                        label="2023"
                                        value="2023"
                                        ></v-checkbox>
                                    </v-form>
                                    <v-btn
                                    color="primary"
                                    @click="addMember"
                                    >
                                    Next
                                    </v-btn>
                                    <v-btn 
                                    text  
                                    @click="e1=4"
                                    >
                                    Cancel
                                    </v-btn>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-card>
                </v-col>
                <v-col
                cols="12"
                md="2"
                >
                <rightMenu/>
                </v-col>
            </v-row>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import leftMenu from "./parts/leftMenu";
import rightMenu from "./parts/rightMenu";
import axios from 'axios'
export default {
    components: { leftMenu,rightMenu},
    props: {
        eventid:String,
    },
    data() {
        return {
            drawer:null,
            e1: 2,
            forday:1,
            valid: true,
            event:{},
            cate:[],
            catenum:[[0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0],],
            gottenCate:['ローテ1','ローテ2','ローテ3','ローテ4','ローテ5','ローテ6','ローテ7','ローテ8',],
            gottenCateNum:8,
            times:[],
            memberyear:["2019"],
            rotename1:"",rotename2:"",rotename3:"",rotename4:"",
            rotename5:"",rotename6:"",rotename7:"",rotename8:"",
            rotetf1:false,rotetf2:false, rotetf3:false,rotetf4:false,
            rotetf5:false,rotetf6:false,rotetf7:false,rotetf8:false,
            nameRules: [
                v => (v && v.length <= 20) || 'Name must be less than 20 characters',
            ],
        };
    },
    created() {
       this.statusCheck();
    },
    methods: {
        statusCheck(){
            axios.get('api/fr/event/'+this.eventid)
                .then((res) => {
                    this.e1=Number(res.data.status)+2;
                    this.event=res.data;
                    this.forday=Number(this.event.daynum);
                    if(this.e1==3){
                        this.getCate();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addCate(){
            this.$refs.form1.validate();
            this.cate=[[this.rotename1,this.rotetf1],[this.rotename2,this.rotetf2],[this.rotename3,this.rotetf3],[this.rotename4,this.rotetf4],[this.rotename5,this.rotetf5],[this.rotename6,this.rotetf6]];
            axios.post('api/fr/event/'+this.eventid+'/cate', {"data":this.cate})
                .then(() => {
                    this.e1=3;
                    this.getCate();
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getCate(){
            axios.get('api/fr/event/'+this.eventid+'/cate')
                .then((res) => {
                    this.gottenCate=res.data;
                    this.gottenCateNum=res.data.length;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addDay(){
            axios.post('api/fr/event/'+this.eventid+'/day',{"data":this.catenum,"gottencates":this.gottenCate})
                .then(() => {
                    this.e1=4;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addTime(){
            axios.post('api/fr/event/'+this.eventid+'/time',{"data":this.times})
                .then(() => {
                    this.e1=5;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addMember(){
            axios.post('api/fr/event/'+this.eventid+'/member',{"data":this.memberyear})
                .then(() => {
                    this.$router.push({name: 'fesrotainer.eventsdetail',params: {eventid: String(this.event.id)}});
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};
</script>