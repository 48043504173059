<template>
    <div class="contents pa-5">
        <v-row>
            <v-col>
                <v-alert
                shaped
                prominent
                type="success"
                >
                    <h1>現在走行中の車両</h1>
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <v-alert
                height="55.5vh"
                class="ma-1 mt-4 pa-4 rounded-lg"
                elevation="12"
                border="left"
                colored-border
                color="light-blue darken-1"
                >
                <div>
                <v-row class="title1card">
                        <v-col class="pb-0">
                            <v-chip label class="ma-2 mb-0" text-color="white" color="red darken-3"><span class="titlechip">2教室目</span></v-chip>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pt-0">
                            <div>
                                <h1 class="ma-2 mt-0 title1">四国</h1>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                </v-alert>
            </v-col>
            <v-col cols="9">
                <v-row v-for="item,index in names" :key="index">
                    <v-col cols="4" class="pb-0 mb-0">
                        <v-alert
                        class="ma-1 mt-4 pa-4 rounded-lg"
                        elevation="12"
                        height="16vh"
                        border="left"
                        colored-border
                        :color="item[1]"
                        >
                            <h2 class="pl-2 pt-5">{{item[0]}}</h2>
                        </v-alert>
                    </v-col>
                    <v-col cols="8" class="pb-0 mb-0">
                        <v-card
                        class="ma-1 mt-4 pa-4 rounded-lg"
                        elevation="12"
                        height="16vh"
                        >
                        <span v-for="itemm,indexx in activecars[index]" :key="indexx" class="pr-2">
                            <img :src="$backurl+'api/media/icons/'+itemm[6][1]">
                        </span>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props:{activecars: {
            type: Array,
            default() {
                return [[],[],[]];
            }
        }},
    data() {
        return {
            names:[['予讃線','light-blue darken-1'],['伊予鉄','orange darken-1'],['留置中','grey darken-1']],
        };
    },
};
</script>
<style scoped>
    .svg{
        height:10vw; 
        width:10vw;
        fill:#00897B;
    }
    h1{
        font-size:60px;
        margin-bottom:0;
    }
    h2{
        font-size:45px;     
    }
    .titlechip{
        font-size:30px; 
        font-weight: bold;
    }
    .title1{
        font-size:80px; 
        font-weight: bold;
    }
    .title1card{
        margin-top:15vh;
    }
</style>