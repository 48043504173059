<template>
    <div>
        <v-app id="inspire">
            <v-card
            class="indigo darken-2 pa-6"
            tile
            v-if="alltrue"
            >
            <v-row>
                <v-col
                cols="12"
                md="2"
                >
                    <leftMenu/>
                </v-col>
                <v-col
                cols="12"
                md="8"
                >
                    <v-card elevation="12">
                        <h2 class="pa-10 pb-1">{{this.event.name}}</h2>
                        <hr>
                        <v-card
                        v-for="(form,index) in forms"
                        :key="index"
                        class="ma-2 pa-4"
                        >
                            <h3>フォームフェッチ待機{{index}}</h3>
                            <h4>{{form.enteryear}}年入学{{form.class}}組{{form.number}}番 {{form.namea}}{{form.nameb}}</h4>
                            <v-form
                                ref="form"
                                >
                            <v-row>
                                
                                    <v-col cols="12" md="4">
                                            <v-select
                                            v-model="form.catedata"
                                            item-text="label"
                                            item-value="data"
                                            label="ローテ種別"
                                            :items="infoselect4"
                                            required
                                            multiple
                                            attach
                                            chips
                                            ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                            <v-select
                                            v-model="form.userdata"
                                            item-text="[0]"
                                            item-value=""
                                            label="メンバー"
                                            :items="gottenMember"
                                            return-object
                                            ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-btn
                                            color="indigo darken-2"
                                            dark
                                            class="ma-1"
                                            @click="submit(form.catedata,form.userdata,index,form.id,form.note)"
                                        >
                                            Fetch
                                        </v-btn>
                                        <v-btn
                                            color="indigo darken-2"
                                            dark
                                            class="ma-1"
                                            @click="deleteform(form.id,index)"
                                        >
                                            ignore
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                           
                            
                        </v-card>
                        <v-btn
                            color="indigo darken-2"
                            dark
                            class="ma-6"
                            @click="statusCheck"
                        >
                            Update data
                        </v-btn>
                    </v-card>
                </v-col>
                <v-col
                cols="12"
                md="2"
                >
                <rightMenu/>
                </v-col>
            </v-row>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import leftMenu from "./parts/leftMenu";
import rightMenu from "./parts/rightMenu";
import axios from 'axios'
export default {
    components: { leftMenu,rightMenu},
    props: {
        eventid:String,
    },
    data() {
        return {
            eventedid:9,
            drawer:null,
            event:{},
            daynum:0,
            forms:[],
            infoselect4:[],
            infodata5:[],
            gottenCate:[],
            gottenTime:[],
            gottenMember:[],
            gottenLike:[],
            gottenSch:[],
            alltrue:true,
        };
    },
    created() {
        this.statusCheck();
        this.getTime();
        this.getCate();
        this.getMember();
        this.getLike();
    },
    methods: {
        statusCheck(){
            axios.get('api/fr/event/'+this.eventid)
                .then((res) => {
                    this.e1=Number(res.data.status)+2;
                    if(res.data.status<4){
                        this.$router.push({name: 'fesrotainer.eventssetting',params: {eventid: String(this.event.id)}});
                    }
                    this.event=res.data;
                    this.eventedid=this.event.id;
                    this.daynum=this.event.daynum;
                    this.getSch();
                })
                .catch(error => {
                    console.log(error);
                });
            axios.get('api/fr/event/form/'+this.eventid)
                .then((res) => {
                    this.forms=res.data
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getCate(){
            axios.get('api/fr/event/'+this.eventid+'/cate')
                .then((res) => {
                    this.gottenCate=res.data;
                    this.makeinfo4();
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getTime(){
            axios.get('api/fr/event/'+this.eventid+'/time')
                .then((res) => {
                    this.gottenTime=res.data;
                    this.makeinfo5();
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getMember(){
            axios.get('api/fr/event/'+this.eventid+'/rote/member/data')
                .then((res) => {
                    this.gottenMember=res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getLike(){
            axios.get('api/fr/event/'+this.eventid+'/member/like/edit')
                .then((res) => {
                    this.gottenLike=res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        makeinfo4(){
            var info4=[]
            for(var i=0;i<this.gottenCate.length;i++){
                info4.push({label:this.gottenCate[i],data:i})
            }
            this.infoselect4=info4
       },
       makeinfo5(){
            var info5=[]
            for(var i=0;i<this.event.daynum;i++){
                var infodata=[]
                for(var ii=0;ii<this.gottenTime.length;ii++){
                    infodata.push(false)
                }
                info5.push(infodata)
            }
            this.infodata5=info5
            this.tablefind=true
        },
        savecatechange(){
            axios.post('api/fr/event/'+this.eventid+'/member/like/edit', {"data":this.gottenLike})
                .then(() => {
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getSch(){
            for(var i=1;i<this.event.daynum+1;i++){
                axios.get('api/fr/event/'+this.eventid+'/member/sch/'+i)
                .then((res) => {
                    this.gottenSch.push(res.data)
                })
                .catch(error => {
                    console.log(error);
                });
            }
        },
        saveschchange(){
            for(var i=1;i<this.event.daynum+1;i++){
                axios.post('api/fr/event/'+this.eventid+'/member/sch/'+i, {"data":this.gottenSch[i-1]})
                    .then(() => {
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
        submit(catedata,userdata,index,formid,formnote){
            var catedatafixed=[]
            for(var i=0;i<this.gottenCate.length;i++){
                catedatafixed.push(false)
            }
            catedata.forEach(item => {
                catedatafixed[item]=true
            })
            catedatafixed.push(Number(userdata[1]))
            catedatafixed.push(userdata[2])
            catedatafixed.push(userdata[0])
            var count=0
            var countfixed=null
            this.gottenLike.forEach(item=>{
                count++
                if(item[this.gottenCate.length]==userdata[1]){
                   countfixed=count
                }
            })
            this.gottenLike[countfixed-1]=catedatafixed
            this.savecatechange()
            for(var ii=1;ii<this.event.daynum+1;ii++){
                var daynum=ii-1
                var schdatafixed=this.forms[index].schdata[daynum]
                schdatafixed.push(Number(userdata[1]))
                schdatafixed.push(userdata[2])
                schdatafixed.push(userdata[0])
                var count2=0
                var countfixed2=null
                this.gottenSch[daynum].forEach(item=>{
                    count2++
                    if(item[this.gottenTime.length]==userdata[1]){
                    countfixed2=count2
                    }
                })
                countfixed2=countfixed2-1
                this.gottenSch[daynum][countfixed2]=schdatafixed
            }
            this.saveschchange()
            axios.post('api/fr/event/'+this.eventid+'/member/note', {"userid":userdata[1],"note":formnote})
                .then(() => {
                })
                .catch(error => {
                    console.log(error);
                });
            this.deleteform(formid,index)
        },
        deleteform(formid,index){
            axios.delete('api/fr/event/form/delete/'+formid)
                .then(() => {
                   this.forms.splice(index,1)
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};
</script>