<template>
    <v-app>
        <header>
            <v-app-bar
            app
            dark
            elevation="4"
            color="indigo darken-2"
            >
                <v-toolbar-title>Afes76</v-toolbar-title>
            </v-app-bar>
        </header>
        <v-container class="pt-16">
            <v-row no-gutters>
                <v-col
                cols="12"
                sm="8"
                >
                </v-col>
                <v-col
                cols="12"
                sm="4"
                >
                    <v-card
                    elevation-8
                    class="ma-8 pa-4 my-16"
                    >
                        <h1 class="font-weight-light">Login</h1>
                        <hr>
                        <v-form class=""
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        
                        >
                        <p class="mt-2 text-danger">{{ getUserMessage }}</p>
                        <v-text-field
                            v-model="email"
                            :counter="40"
                            :rules="nameRules"
                            label="Email"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="pass"
                            type="password"
                            :counter="40"
                            :rules="passRules"
                            label="Password"
                            required
                            class="mb-4"
                        ></v-text-field>
                        <v-btn
                            :disabled="!valid"
                            color="indigo darken-2"
                            dark
                            block
                            class="mr-4"
                            @click="login"
                        >
                            Login
                        </v-btn>

                    </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-footer
        color="indigo darken-2"
        padless
        dark
        absolute
        >
            <v-col
            class="indigo darken-2 py-4 text-center white--text"
            cols="12"
            >
               knomo Systems
            </v-col>
        </v-footer>
    </v-app>
</template>

<script>
    import axios from 'axios'
    export default {
    data() {
        return {
            email: 'i',
            pass: '',
            error: false,
            getUserMessage: "",
            colors: [
          'green',
          'secondary',
          'yellow darken-4',
          'red lighten-2',
          'orange darken-1',
        ],
        cycle: false,
        slides: [
          'First',
          'Second',
          'Third',
          'Fourth',
          'Fifth',
        ],
        nameRules: [
            v => !!v || 'Name is required',
            v => (v && v.length <= 40) || 'Name must be less than 40 characters',
        ],
        passRules: [
            v => !!v || 'Name is required',
        ],
        valid: true,
        };
    },
    methods: {
        login() {
            axios
                .get('sanctum/csrf-cookie')
                .then(function(response){
                    console.log(response)
                    axios.post('api/login', {
                        "email": this.email,
                        "password": this.pass,
                    })
                    .then(function(response){
                        if( response.data.status_code == 200 ) {
                            this.$router.push("/home");
                        }else{
                            this.getUserMessage = 'ログインに失敗しました。'
                        }
                        
                    }.bind(this))
                    .catch(function(error){
                        console.log(error);
                        this.getUserMessage = 'ログインに失敗しました。'
                    }.bind(this))
                }.bind(this))
                .catch(function(error){
                    console.log(error);
                }.bind(this))
        }

    }
};
</script>