<template>
    <div>
        <v-app id="inspire">
            <v-card
            class="indigo darken-2 pa-6"
            tile
            >
            <v-row>
                <v-col
                cols="12"
                md="2"
                >
                    <leftMenu></leftMenu>
                </v-col>
                <v-col
                cols="12"
                md="8"
                >
                    <v-card elevation="12">
                        <v-form class="pa-10"
                            ref="form"
                            v-model="valid"
                            lazy-validation
                            
                            >
                            <p class="mt-2 text-danger">{{ getUserMessage }}</p>
                            <v-file-input
                                v-model="file"
                                label="インポートファイル"
                                required
                                show-size
                                truncate-length="15"
                            ></v-file-input>
                            <v-btn
                                color="indigo darken-2"
                                dark
                                block
                                class="mr-4"
                                @click="submit"
                            >
                                Upload
                            </v-btn>

                        </v-form>
                        <h2 class="pa-10 pb-1">部員の一覧</h2>
                        <v-simple-table class="pa-10">
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    Id
                                </th>
                                <th class="text-left">
                                    enteryear
                                </th>
                                <th class="text-left">
                                    name
                                </th>
                                <th class="text-left"></th>
                                <th class="text-left"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(member, index) in members" :key="index">
                                    <th scope="row">{{ member.id }}</th>
                                    <td>{{ member.enteryear }}</td>
                                    <td>{{ member.name }}</td>
                                    <td>
                                        <v-btn
                                            color="error"
                                            class="mr-4"
                                            dark
                                            @click="deleteTravel(member.id)"
                                        >
                                            delete
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
                <v-col
                cols="12"
                md="2"
                >
                <rightMenu/>
                </v-col>
            </v-row>
            </v-card>
        </v-app>
    </div>
</template>

<script>
import leftMenu from "./parts/leftMenu";
import rightMenu from "./parts/rightMenu";
import axios from 'axios'
export default {
    components: { leftMenu,rightMenu},
    data() {
        return {
            drawer:null,
            members:"",
            file:null,
            valid: true,
        };
    },
    mounted() {
       this.getMembers();
    },
    methods: {
        async submit(){
            let formData = new FormData()
            formData.append('file', this.file)
            await axios.post('api/fr/member', formData,{headers:{'Content-Type': 'multipart/form-data',},})
                .then(() => {
                    this.$router.push({name: 'fesrotainer.memberslist'});
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getMembers(){
           axios.get('api/fr/member')
                .then((res) => {
                    this.members = res.data;
                }); 
        },
        deleteTravel(id) {
            axios.delete('api/fr/member/' + id)
            .then(() => {
                this.getMembers();
            });
        },
    }
};
</script>