<template>
    <div
     >  <v-app id="inspire">
            <v-card
            class="indigo darken-2 pa-6"
            tile
            >
                <v-row>
                        <v-col
                        cols="12"
                        md="2"
                        >   
                            <leftMenu/>
                        </v-col>
                        <v-col
                        cols="12"
                        md="10">
                            <v-card
                            elevation="12"
                            >
                                <v-form class="pa-10"
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation
                                    >
                                    <h3>動画アップロード</h3>
                                    <div 
                                    class="pa-4"
                                    v-for="n in 2" :key="n"
                                    >
                                        <h4>動画ファイル{{n}}</h4>
                                        <v-row
                                        >
                                            <v-col
                                            cols="12"
                                            md="4"
                                            >
                                                <videoPre @end="console()" :options="videoOptions[n-1]"/>
                                            </v-col>
                                            <v-col
                                            cols="12"
                                            md="8"
                                            >   
                                                <v-file-input
                                                    v-model="videofile[n-1]"
                                                    :label="'動画ファイル更新'+n"
                                                    show-size
                                                    truncate-length="15"
                                                ></v-file-input>
                                                <v-btn
                                                    :disabled="!valid"
                                                    color="indigo darken-2"
                                                    dark
                                                    class="mr-4"
                                                    @click="submitmain(n)"
                                                >
                                                    Create
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class="pa-4">
                                        
                                    </div>
                                </v-form>
                                <v-form class="pa-10"
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation
                                    >
                                    <h3>画像アップロード</h3>
                                    <p>拡張子は「.JPG」にすること!!</p>
                                    <div 
                                    class="pa-4"
                                    v-for="n in 5" :key="n"
                                    >
                                        <h4>動画ファイル{{n}}</h4>
                                        <v-row
                                        >
                                            <v-col
                                            cols="12"
                                            md="4"
                                            >
                                                <img :src="$backurl+'api/media/picture/'+n" style="width:100%;"/>
                                            </v-col>
                                            <v-col
                                            cols="12"
                                            md="8"
                                            >   
                                                <v-file-input
                                                    v-model="picturefile[n-1]"
                                                    :label="'画像ファイル更新'+n"
                                                    show-size
                                                    truncate-length="15"
                                                ></v-file-input>
                                                        <v-btn
                                                    :disabled="!valid"
                                                    color="indigo darken-2"
                                                    dark
                                                    class="mr-4"
                                                    @click="submitsub(n)"
                                                >
                                                    Create
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class="pa-4">
                                        
                                    </div>
                                </v-form>
                            </v-card>  
                        </v-col>
                </v-row>
            </v-card>
            <v-dialog
            v-model="dialog"
            width="500"
            ><v-card>
                <v-card-title class="text-h5 grey lighten-2">
                API通信エラー
                </v-card-title>
                <v-card-text>
                {{error}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                >
                    close
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-app>
       
            
    </div>
</template>
<script>
import axios from 'axios'
import leftMenu from "./parts/leftMenu";
import videoPre from "./parts/videopre";
import 'video.js/dist/video-js.css';
export default {
    components: {leftMenu,videoPre},
    data() {
        return {
            dialog:false,
            error:"",
            user: "",
            drawer:null,
            valid: true,
            videofile:[[],[]],
            picturefile:[[],[],[],[],[]],
            videoOptions:[
                {
                    controls: true,
                    fluid: true,
                    sources: [
                        {
                            src: this.$backurl+"api/media/movie/1",
                            type: "video/mp4"
                        }
                    ]
                },
                {
                    controls: true,
                    loop:true,
                    fluid: true,
                    sources: [
                        {
                            src: this.$backurl+"api/media/movie/2",
                            type: "video/mp4"
                        }
                    ]
                }
            ]
            
        };
    },
    
    methods: {
        submitmain(movieid){
            let formData = new FormData()
            formData.append('file', this.videofile[movieid-1])
            axios.post('api/media/movie/'+movieid, formData,{headers:{'Content-Type': 'multipart/form-data',},})
                .then(() => {
                })
                .catch(error => {
                    console.log(error);
                    this.error=error
                    this.dialog=true
                });
        },
        submitsub(pictureid){
            let formData = new FormData()
            formData.append('file',this.picturefile[pictureid-1])
            axios.post('api/media/picture/'+pictureid, formData,{headers:{'Content-Type': 'multipart/form-data',},})
                .then(() => {
                })
                .catch(error => {
                    console.log(error);
                    this.error=error
                    this.dialog=true
                });
        },
        console(){
            console.log('jh')
        }
    },
    mounted() {
        axios.get("api/user").then(response => {
            this.user = response.data;
        });
    },
};
</script>