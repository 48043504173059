<template>
    <div>
        <v-app id="inspire">
            <v-main v-if="!loading">
            <v-row align-content="center" class="text-center" height="100vh" style="height:100vh;">
                <v-col
                cols="12"
                >
                    <v-progress-circular
                        :size="70"
                        :width="7"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </v-col>
                
            </v-row>
                
            </v-main>
            <v-main v-if="loading">
                <v-row
                no-gutters
                >
                    <v-col
                    cols="9"
                    >
                        <v-form 
                        ref="form3"
                        v-model="valid"
                        lazy-validation
                        >
                            <v-simple-table 
                            class="pa-1"
                            fixed-header
                            height="95vh"
                             dense
                            >
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            ローテ名
                                        </th>
                                        <th class="text-left" v-for="(status,index) in gottenTimeNum" :key="index">
                                            {{gottenTime[index]}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(member, index) in data" :key="index">
                                        <th scope="row">{{ gottenFrame[index] }}</th>
                                        <td v-for="(status,indexx) in gottenTimeNum" :key="indexx" :class="valiClass[index][indexx]">
                                            <draggable tag="div" group="ITEMS" :list="data[index][indexx]" @start="dragStart" @sort="makeValiClass">
                                                <v-card v-for="item,indexxx in data[index][indexx]" class="pa-1" :key="indexxx" light  :class="gradeColor(data[index][indexx][indexxx][2])">
                                                    <h4>{{data[index][indexx][indexxx][0]}}</h4>
                                                </v-card>
                                            </draggable>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-form>
                        <p></p>
                    </v-col>
                    <v-col
                    cols="3"
                    >
                    <v-card
                    class="red darken-2 pa-6"
                    dark
                    height="95vh"
                    tile
                    >
                        <h3 class="font-weight-thin">Members</h3>
                        <v-divider></v-divider>
                        <v-card tile height="80vh" max-height="80vh" class="overflow-auto ma-2 red darken-2">
                            <v-card v-for="(i,index) in gottenMemberData" :key="index" class="grey lighten-2 pa-1 ma-1" light>
                                <v-row class="ma-2">
                                    <v-col cols="9" align-self="center">
                                        <v-row>
                                            <v-col class="ma-1">
                                                <draggable tag="div" id="ice" class="icing" group="ITEMS" :list="gottenMember[index]"  @start="dragStart" @sort="makeValiClass">
                                                    <v-card v-for="item,indexx in gottenMember[index]" class="pa-1" :key="indexx" light  :class="gradeColor(gottenMember[index][indexx][2])">
                                                        <h4>{{gottenMember[index][indexx][0]}}</h4>
                                                    </v-card>
                                                </draggable>
                                                
                                            </v-col>
                                            <v-col>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-card class="grey lighten-4 pa-1" v-bind="attrs" v-on="on" light>
                                                        <h4>{{gottenMemberData[index][0]}}</h4>
                                                    </v-card>
                                                    </template>
                                                    
                                                    <span>{{gottenMemberData[index][3]}}</span>
                                                </v-tooltip>
                                                
                                            </v-col>
                                        </v-row>
                                        
                                        <v-chip-group column dark>
                                            <div v-for="cate,indexc in gottenCate" :key="indexc">
                                                <v-chip v-if="canEnt(index,indexc)" class="red darken-1">{{gottenCate[indexc]}}</v-chip>
                                            </div>
                                        </v-chip-group>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-btn
                                        class="mx-2"
                                        @click="incMem(index)"
                                        fab
                                        dark
                                        color="indigo"
                                        >
                                            <v-icon dark>
                                                mdi-plus
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        color="indigo"
                                        >
                                            {{gottenRotenum[index]}}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                
                            </v-card>
                        </v-card>
                        
                            <v-card tile height="5vh" max-height="10vh" class="overflow-auto ma-2 green" fill-height>   
                                <draggable tag="div" group="ITEMS" :list="rubbish" class="py-4">
                                </draggable>
                            </v-card>
                    </v-card>
                    </v-col>
                </v-row>
                <v-footer
                color="indigo darken-2"
                dark
                fixed
                >
                    <v-row
                    no-gutters
                    >
                        <v-col cols="1"></v-col>
                        <v-col cols="3">
                            <h2 class="font-weight-thin">Fes Rotainer</h2>
                        </v-col>
                        <v-col cols="5"></v-col>
                        <v-col
                            cols="3"
                        >
                            <v-btn
                            color="primary"
                            class="ma-1"
                            @click="save"
                            >
                            Save
                            </v-btn>
                            <v-btn
                            color="primary"
                            class="ma-1"
                            @click="excelExport()"
                            >
                            Export
                            </v-btn>
                            <v-btn
                            color="success"
                            class="ma-1"
                            @click="getRotenum"
                            >
                            Count
                            </v-btn>
                            <v-btn
                            color="error"
                            @click="quit"
                            >
                            Quit
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-footer>
            </v-main>
        </v-app>
    </div>
</template>
<script>

import draggable from 'vuedraggable';
import axios from 'axios'
export default {
    components:{
        'draggable':draggable,
    },
    props: {
        eventid:String,
        day:String,
    },
    data() {
        return {
            data:{},
            daynum:0,
            loading:false,
            gottenTime:[],
            gottenTimeNum:16,
            valid: true,
            gottenFrame:[],
            gottenFrameNum:0,
            gottenFrameCate:[],
            gottenMember:[],
            gottenMemberData:[],
            gottenCate:[],
            gottenCateNum:0,
            gottenLice:[],
            gottenSch:[],
            gottenRotenum:[],
            rubbish:[],
            valiClass:[],
            valiClassFirst:[],
        };
    },
    created() {
       this.statusCheck();
       this.getTime();
       this.getFrame();
       this.getFrameCate();
       this.getMember();
       this.getMemberData();
       this.getCate();
       this.getLice();
       this.getLike();
       this.getSch();
       this.makeValiClass();
    },
    methods: {
        statusCheck(){
            axios.get('api/fr/event/'+this.eventid+'/rote/'+this.day)
                .then((res) => {
                    this.data=res.data;
                    this.getRotenum();
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getLike(){
            axios.get('api/fr/event/'+this.eventid+'/member/like/edit')
                .then((res) => {
                    this.gottenLike=res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getTime(){
            axios.get('api/fr/event/'+this.eventid+'/time')
                .then((res) => {
                    this.gottenTime=res.data;
                    this.gottenTimeNum=res.data.length;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getFrame(){
            axios.get('api/fr/event/'+this.eventid+'/frame/'+this.day)
                .then((res) => {
                    this.gottenFrame=res.data;
                    this.gottenFrameNum=res.data.length;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getFrameCate(){
            axios.get('api/fr/event/'+this.eventid+'/frame/'+this.day+'/cate')
                .then((res) => {
                    this.gottenFrameCate=res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getMember(){
            axios.get('api/fr/event/'+this.eventid+'/rote/member')
                .then((res) => {
                    this.gottenMember=res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getMemberData(){
            axios.get('api/fr/event/'+this.eventid+'/rote/member/data')
                .then((res) => {
                    this.gottenMemberData=res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getCate(){
            axios.get('api/fr/event/'+this.eventid+'/cate')
                .then((res) => {
                    this.gottenCate=res.data;
                    this.gottenCateNum=res.data.length;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getLice(){
            axios.get('api/fr/event/'+this.eventid+'/member/edit')
                .then((res) => {
                    this.gottenLice=res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getSch(){
            axios.get('api/fr/event/'+this.eventid+'/member/sch/'+this.day)
                .then((res) => {
                    this.gottenSch=res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getRotenum(){
            axios.post('api/fr/event/'+this.eventid+'/rote/'+this.day+'/num', {"data":this.data})
                .then((res) => {
                    this.gottenRotenum=res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        makeValiClass(originalEvent){
            console.log(originalEvent)
            axios.get('api/fr/event/'+this.eventid+'/rote/'+this.day+'/vc')
                .then((res) => {
                    this.valiClass=res.data;
                    this.loading=true;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        save(){
            axios.post('api/fr/event/'+this.eventid+'/rote/'+this.day, {"data":this.data})
                .then(() => {
                })
                .catch(error => {
                    console.log(error);
                });
        },
        excelExport(){
            axios.get('api/fr/event/'+this.eventid+'/rote/'+this.day+'/excel')
                .then((res) => {
                    window.location = res.request.responseURL;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        quit(){
            this.$router.push({name:'fesrotainer.eventsdetail',params: {eventid:String(this.eventid)}});
        },
        canEnt(index,indexc){
            if(this.gottenLice[index][indexc] && this.gottenLike[index][indexc]){
                return true
            }else{
                return false;
            }
        },
        incMem(index){
            var num=this.gottenMember[index].length;
            if(num>0){
                return
            }
            this.gottenMember[index].push([this.gottenMemberData[index][0],this.gottenMemberData[index][1],this.gottenMemberData[index][2]]);
        },
        gradeColor(grade){
            if (grade=="2018") {
                return "deep-purple lighten-2";
            }else if (grade=="2019"){
                return "light-blue lighten-2";
            }else if (grade=="2020"){
                return "yellow lighten-2";
            }else if (grade=="2021"){
                return "green lighten-2";
            }else if (grade=="2022"){
                return "pink lighten-2";
            }else{
                return "green";
            }
        },
        dragStart(originalEvent){
            let fesmemberId=Number(originalEvent.item._underlying_vm_[1]);
            for(let p=0;p<this.gottenMember.length;p++){
                if(this.gottenLice[p][this.gottenCateNum]==fesmemberId){
                    var liceIndex=p;
                    break;
                }
            }
            for(let pp=0;pp<this.gottenMember.length;pp++){
                if(this.gottenSch[pp][this.gottenTimeNum]==fesmemberId){
                    var schIndex=pp;
                    break;
                }
            }
            let roted=[];
            for(let ppp=0;ppp<this.gottenFrameNum;ppp++){
                for(let pppp=0;pppp<this.gottenTimeNum;pppp++){
                    if(this.data[ppp][pppp].length>0){
                        if(this.data[ppp][pppp][0].length>0){
                            if(this.data[ppp][pppp][0][1]==String(fesmemberId)){
                                roted.push(pppp);
                            }
                        }
                        
                    }
                }
            }
            console.log(roted);
            for(let ppppp=0;ppppp<this.gottenFrameNum;ppppp++){
                var cateIndex=this.gottenFrameCate[ppppp]
                for(let pppppp=0;pppppp<this.gottenTimeNum;pppppp++){
                    if(roted.indexOf(pppppp)!=-1 || this.gottenLice[liceIndex][cateIndex-1]==false|| this.gottenSch[schIndex][pppppp]==false || this.gottenLike[liceIndex][cateIndex-1]==false){
                        this.valiClass[ppppp][pppppp]=["red"];
                    }
                }
                this.valiClass=this.valiClass.map(function( value ) {
                    return value;    
                });
            }
            
        },
    }
};
</script>