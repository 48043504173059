<template>
    <div
     >  <v-app id="inspire">
            <v-card
            class="indigo darken-2 pa-6"
            tile
            >
                <v-row>
                        <v-col
                        cols="12"
                        md="2"
                        >   
                            <leftMenu/>
                        </v-col>
                        <v-col
                        cols="12"
                        md="10">
                            <v-card
                            elevation="12"
                            >
                                <div class="pa-10">
                                    <v-card class="teal lighten-1 pa-4 ma-4" height="30vh">
                                        <div class="pa-2">
                                            <h3 dark>登録済み</h3>
                                        </div>
                                        <draggable tag="div" group="ITEMS" :list="registered" @end="makedata" id="4">
                                            <v-chip filter v-for="item,index in registered" class="pa-2 ma-1" :key="index" @click="edit(item[4])" light>
                                                {{item[0]}}
                                            </v-chip>
                                        </draggable>
                                    </v-card>
                                    <v-row>
                                        <v-col
                                        cols="12"
                                        md="4"
                                        v-for="name,indexp in names"
                                        
                                        :key="indexp"
                                        >
                                            <v-card class="pa-4 ma-4" :class="name[1]" height="30vh">
                                                <div class="pa-2">
                                                    <h3 dark>{{name[0]}}</h3>
                                                </div>
                                                <draggable tag="div" group="ITEMS" :list="vehicle[indexp]" @end="makedata" :id="indexp+1">
                                                    <v-chip filter v-for="item,index in vehicle[indexp]" class="pa-2 ma-1" :class="makecolorclass(item[5])" :key="index" @click="edit(item[4])" light >
                                                        {{item[0]}}
                                                    </v-chip>
                                                </draggable>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                        cols="12"
                                        md="4"
                                        class="pa-7"
                                        >
                                            <v-btn
                                            color="primary"
                                            class="ma-1"
                                            @click.stop="dialog=true"
                                            >
                                            Add new vehicle
                                            </v-btn>
                                            <v-btn
                                            color="primary"
                                            class="ma-1"
                                            @click="statusCheck()"
                                            >
                                            Reload
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card>  
                        </v-col>
                </v-row>
                
            </v-card>
            <v-dialog
            v-model="dialog"
            width="400"
            persistent
            ><v-card>
                <v-card-title class="text-h5" v-if="!reuse">
                新規車両追加
                </v-card-title>
                <v-card-title class="text-h5" v-if="reuse">
                車両編集
                </v-card-title>
                <v-card-text>
                    <v-form
                    ref="form"
                    v-model="valid"
                    >
                        <v-text-field
                        label="車両名称"
                        :rules="reasonRules"
                        v-model="formenter[0]"
                        hint="E233系(伊藤悠悟)"
                        ></v-text-field>
                        <v-text-field
                        label="所有者"
                        :rules="reasonRules"
                        v-model="formenter[1]"
                        hint="伊藤悠悟"
                        ></v-text-field>
                        <v-text-field
                        label="車両形式"
                        :rules="reasonRules"
                        v-model="formenter[2]"
                        hint="E233系"
                        ></v-text-field>
                        <v-text-field
                        label="両数"
                        :rules="reasonRules"
                        v-model="formenter[3]"
                        hint="15"
                        ></v-text-field>
                        <v-select
                            v-model="formenter[4]"
                            item-text="[0]"
                            label="車両アイコン選択"
                            :items="infoselect"
                            return-object
                        ></v-select>
                        <v-textarea
                        v-model="formenter[5]"
                        :rules="reasonRules"
                        label="備考"
                        hint="「パンタグラフ破損」等"
                        ></v-textarea>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    v-if="!reuse"
                    @click="dialog=false"
                >
                    close
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    :disabled="!valid"
                    v-if="!reuse"
                    @click="submit()"
                >
                    send
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    v-if="reuse"
                    @click="closeedit()"
                >
                    close
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    :disabled="!valid"
                    v-if="reuse"
                    @click="submitedit()"
                >
                    update
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-app>
       
            
    </div>
</template>
<script>
import axios from 'axios'
import leftMenu from "./parts/leftMenu";
import draggable from 'vuedraggable';
export default {
    components: {leftMenu,'draggable':draggable,},
    data() {
        return {
            user: "",
            reuse:false,
            locate:"",
            dialog:false,
            valid:false,
            formenter:["","","","","",""],
            registered:[],
            vehicle:[
               [],[],[]
            ],
            names:[['横須賀系統','indigo lighten-1'],['東海道系統','amber lighten-1'],['横浜系統','green lighten-1']],
            rubbish:[],
            infoselect:[],
            reasonRules: [
                v => !!v || 'この項目は必須です',
            ],
        };
    },
    
    methods: {
        statusCheck(){
            axios.get('api/vm/cars/nonactive/syonan')
                .then((res) => {
                    this.registered=res.data
                })
                .catch(error => {
                    console.log(error)
                });
            axios.get('api/vm/cars/active/syonan')
                .then((res) => {
                    this.vehicle=res.data
                })
                .catch(error => {
                    console.log(error)
                });
        },
        iconget(){
            axios.get('api/vm/icons')
                .then((res) => {
                    this.infoselect=res.data
                })
                .catch(error => {
                    console.log(error)
                }); 
        },
        edit(id){
            this.reuse=true
            this.dialog=true
            this.editingcarid=id
             axios.get('api/vm/cars/'+id)
                .then((res) => {
                    this.formenter=res.data
                })
                .catch(error => {
                    console.log(error)
                });
        },
        closeedit(){
            this.formenter=["","","","","",""]
            this.dialog=false
            this.reuse=false
        },
        submitedit(){
            axios.put('api/vm/cars/'+this.editingcarid, {
                "name":this.formenter[0],
                "owner":this.formenter[1],
                "classname":this.formenter[2],
                "length":this.formenter[3],
                "icon":this.formenter[4],
                "note":this.formenter[5],
            })
                .then(() => {
                    this.closeedit();
                    this.statusCheck()
                })
                .catch(error => {
                    console.log(error);
                });
        },
        makecolorclass(color){
            if (color=="red") {
                return "pink lighten-2";
            }else{
                return "";
            }
        },
        submit(){
            axios.post('api/vm/cars/nonactive/syonan', {
                "name":this.formenter[0],
                "owner":this.formenter[1],
                "classname":this.formenter[2],
                "length":this.formenter[3],
                "icon":this.formenter[4],
                "note":this.formenter[5],
            })
                .then(() => {
                    this.dialog=false
                    this.statusCheck()
                })
                .catch(error => {
                    console.log(error);
                });
        },
        makedata(event){
            var toadress=event.to.id
            var fromadress=event.from.id
            var carid=event.item._underlying_vm_[4]
            if(fromadress=="4"){
                if(toadress=="1" || toadress=="2" ||toadress=="3"){
                    this.locate=toadress
                    this.changelocate(carid)
                    axios.post('api/vm/cars/'+carid+'/active')
                        .then(() => {
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            }else if(fromadress=="1" || fromadress=="2" || fromadress=="3"){
                if(toadress=="1" || toadress=="2" ||toadress=="3"){
                    this.locate=toadress
                    this.changelocate(carid)
                }else if(toadress=="4"){
                    this.locate=0
                    this.changelocate(carid)
                    axios.delete('api/vm/cars/'+carid+'/active')
                        .then(() => {
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            }
        },
        changelocate(carid){
            axios.put('api/vm/cars/'+carid+'/locatechange', {"locate":this.locate})
                .then(() => {
                    this.statusCheck()
                })
                .catch(error => {
                    console.log(error);
                });
        },
        
    },
    mounted(){
        this.statusCheck()
        this.iconget()
    }
};
</script>