<template>
    <div>
        <v-card
        elevation="12"
        height="95vh"
        
        >
        <v-navigation-drawer
            floating
            permanent
        >   
            <div
            class="pa-6"
            >
            <h1>afes76</h1> 
            <h2>For ARC</h2>
            <p>By knomo Systems</p>
            </div>
            
            <v-list
            dense
            rounded
            >
            <v-list-item
                v-for="item in items"
                :key="item.title"
                link
            >
                <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <router-link v-bind:to="{name:item.link}">
                        <strong><v-list-item-title class="link">{{ item.title }}</v-list-item-title></strong>
                    </router-link>
                
                </v-list-item-content>
            </v-list-item>
            </v-list>
            <template v-slot:append>
                <div class="pa-2">
                <v-btn block dark color="indigo darken-2" @click="logout()">
                    Logout
                </v-btn>
                </div>
            </template>
        </v-navigation-drawer>
        </v-card>
        
            
    </div>
</template>

<script>
import axios from 'axios'
export default {
   data() {
        return {
            items: [
                { title: 'Home', icon: 'home',link:'Home' },
                { title: 'Rotainer', icon: 'mdi-account-convert',link:'fesrotainer.home'  },
                { title: 'Vehicle Manage(SY)', icon: 'train',link:'VManageSyonan'  },
                { title: 'Vehicle Manage(SH)', icon: 'train',link:'VManageShikoku'  },
                { title: 'Media Upload', icon: 'mdi-movie-settings',link:'MediaUp'  },
                { title: 'Display', icon: 'mdi-animation-play',link:'Display'  },
            ],
        };
    },
    methods:{
        logout(){
            axios.get("api/logout")
            .then(function(){
                this.$router.push({name: 'Login'});
            }.bind(this))
            .catch(function(error){
                console.log(error);
            });
        },
        
    }
};
</script>
<style>
    .link{
        color:black;
        text-decoration: none;
    }
</style>