<template>
    <div>
        <v-app id="inspire">
            <v-card
            class="indigo darken-2 pa-6"
            tile
            >
            <v-row>
                <v-col
                cols="12"
                md="2"
                >
                    <leftMenu/>
                </v-col>
                <v-col
                cols="12"
                md="8"
                >
                    <v-card elevation="12">
                        <h2 class="pa-10 pb-1">{{this.event.name}}</h2>
                        <hr>
                        <v-container>
                            <router-link v-bind:to="{name: 'fesrotainer.eventslicechange',params: {eventid: String(this.eventedid)}}">
                                <v-btn block dark color="error" class="mb-4">
                                    資格編集
                                </v-btn>
                            </router-link>
                            <router-link v-bind:to="{name: 'fesrotainer.eventslikechange',params: {eventid: String(this.eventedid)}}">
                                <v-btn block dark color="warning" class="mb-4">
                                    希望編集
                                </v-btn>
                            </router-link>
                            <div v-for="i in this.daynum" v-bind:key="i+3">
                                <v-btn block dark color="success" class="mb-4" @click="linkSch(i)">
                                    {{i}}日目のスケジュールを編集
                                </v-btn>
                            </div>
                            <v-btn v-for="index in this.daynum" v-bind:key="index" block dark color="primary" class="mb-4" @click="linkRote(index)">
                                {{index}}日目を編集
                            </v-btn>
                            <v-btn block dark color="pink lighten-1" class="mb-4" @click="linkForm()">
                                フォームフェッチ
                            </v-btn>
                        </v-container>
                    </v-card>
                </v-col>
                <v-col
                cols="12"
                md="2"
                >
                <rightMenu/>
                </v-col>
            </v-row>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import leftMenu from "./parts/leftMenu";
import rightMenu from "./parts/rightMenu";
import axios from 'axios'
export default {
    components: { leftMenu,rightMenu},
    props: {
        eventid:String,
    },
    data() {
        return {
            eventedid:9,
            drawer:null,
            event:{},
            daynum:0,
            formid:"",
        };
    },
    created() {
       this.statusCheck();
    },
    methods: {
        statusCheck(){
            axios.get('api/fr/event/'+this.eventid)
                .then((res) => {
                    this.e1=Number(res.data.status)+2;
                    if(res.data.status<4){
                        this.$router.push({name: 'fesrotainer.eventssetting',params: {eventid: String(this.event.id)}});
                    }
                    this.event=res.data;
                    this.eventedid=this.event.id;
                    this.daynum=this.event.daynum;
                    if(this.event.formid!=""){
                        this.formid=true
                    }else{
                        this.formid=false
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        linkSch(day){
            this.$router.push({name:'fesrotainer.eventsschchange',params: {eventid:String(this.eventedid),day:day}});
        },
        linkRote(day){
            this.$router.push({name:'fesrotainer.eventsroteedit',params: {eventid:String(this.eventedid),day:day}});
        },
        linkForm(){
                this.$router.push({name:'fesrotainer.eventform',params: {eventid:String(this.eventedid)}});
        }
    }
};
</script>