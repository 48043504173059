<template>
    <div>
        <video ref="videoPlayer" class="video-js"></video>
        <v-btn
            color="indigo darken-2"
            dark
            class="mr-4"
            @click="submitsub()"
        >a</v-btn>
    </div>
</template>

<script>
import videojs from 'video.js';
export default {
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
            console.log('onPlayerReady', this);
        })
    },
    methods:{
        submitsub(){
            console.log(this.player)
        }
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }
    }
};
</script>