<template>
    <div>
        <v-row>
            <v-col cols="3" class="pa-0 pl-4">
                <v-alert
                height="15vh"
                class="ma-1 pa-4 rounded-lg"
                elevation="12"
                border="left"
                colored-border
                :color="colormake()"
                >
                <div>
                <v-row>
                        <v-col class="pb-0">
                            <v-chip label class="ma-2 mb-0" text-color="white" color="red darken-3"><h2>運行情報</h2></v-chip>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pt-0">
                            <div>
                                <h1 class="ma-2 mt-0">{{statusname[datas[1]]}}</h1>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                </v-alert>
            </v-col>
            <v-col cols="9" class="pa-0 pr-4">
                <v-card
                class="ma-1 pa-4 rounded-lg"
                elevation="12"
                height="15vh"
                >
                    <v-row>
                        <v-col
                        cols="2"
                        >
                            <div v-if="datas[3][0]" style="text-align: center">
                                <svg class="svg svg1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>circle-outline</title><path d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>
                            </div>
                            <div v-if="datas[3][1]" style="text-align: center">
                                <svg class="svg svg2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>triangle-outline</title><path d="M12,2L1,21H23M12,6L19.53,19H4.47" /></svg>
                            </div>
                            <div v-if="datas[3][2]" style="text-align: center">
                                <svg class="svg svg3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>close</title><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
                            </div>
                        
                        </v-col>
                        <v-col
                        cols="5"
                        >
                            <div style="height:13vh;display: flex;align-items: center;">
                                <p class="statusshow pr-3">{{datas[0][0]}}</p>
                                <p class="statusshoweng">{{english}}</p>
                            </div>
                        
                        </v-col>
                        <v-col
                        cols="5"
                        >
                            <div style="height:13vh;display: flex;align-items: center;">
                                <p class="statusreason">{{datas[2]}}</p>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {datas: {
            type: Array,
            default() {
                return [['遅れ','2'],'0','データ無し',[false,false,false]];
            }
        }},
    data() {
        return {
            statusname:['湘南レイアウト','四国レイアウト','京津レイアウト',],
            iconshow:[false,false,false],
            english:"nodata",
        };
    },
    created() {
        this.statusCheck();
    },
    watch:{
        datas: {
            handler: function() {
                this.statusCheck()
            },
            deep : true,
            immediate: true
        }
    },
    methods:{
        statusCheck(){
            if(this.datas[0][0]=="平常運行"){
                this.english="Nomal"
            }else if(this.datas[0][0]=="遅れ"){
                this.english="Delay"
            }else if(this.datas[0][0]=="ダイヤ乱れ"){
                this.english="Timetable disruption"
            }else if(this.datas[0][0]=="未開通"){
                this.english="Not opened"
            }else if(this.datas[0][0]=="運転見合わせ" || this.datas[0][0]=="一時停止中"){
                this.english="Suspended"
            }else if(this.datas[0][0]=="混雑"){
                this.english="Congestion"
            }else if(this.datas[0][0]=="終了"){
                this.english="Ended"
            }
        },
        colormake(){
            if(this.datas[1]=="0"){
                return "yellow darken-2"
            }else if(this.datas[1]=="2"){
                return "light-blue darken-1"
            }else if(this.datas[1]=="3"){
                return "indigo darken-1"
            }else{
                return "pink darken-4"
            }
        }
    },
};
</script>
<style scoped>
    .svg{
        height:12vh; 
        width:12vh;
    }
    .svg1{
        fill:#81C784;
        filter:drop-shadow(0 0 8px #81C784);
    }
    .svg2{
        fill:#FFEE58;
        filter:drop-shadow(0 0 8px #FFEE58);
    }
    .svg3{
        fill:#FF8A65;
        filter:drop-shadow(0 0 8px #FF8A65);
    }
    h1{
        font-size: 40px;
    }
    .statusshow{
        font-size: 45px;
        font-weight: bolder;
    }
    .statusshoweng{
        font-size: 25px;
        font-weight: bolder;
    }
    .statusreason{
        font-weight: bolder;
    }
</style>