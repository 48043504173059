<template>
    <div>
        <v-alert
        height="15vh"
        class="ma-1 pa-4 rounded-lg"
        elevation="12"
        border="left"
        colored-border
        color="pink darken-4"
        >
        <div class="pl-2">
            <v-row>
                <v-col class="pb-0">
                    <p class="statusshow mb-0">Welcome to Azabu Railway Club</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-0">
                    <div>
                        <p class="statusshoweng mb-0">麻布学園鉄道研究部へようこそ!!</p>
                    </div>
                </v-col>
            </v-row>
        </div>
        </v-alert>
    </div>
</template>

<script>
export default {
    
};
</script>
<style scoped>
    h1{
        font-size: 40px;
    }
    .statusshow{
        font-size: 45px;
        font-weight: bolder;
    }
    .statusshoweng{
        font-size: 25px;
        font-weight: bolder;
    }
    .statusreason{
        font-weight: bolder;
    }
</style>