<template>
    <div class="contents">
        <v-row class="mb-0 pb-0">
            <v-col cols="1" class="pb-0"></v-col>
            <v-col cols="3" class="pb-0">
                <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>camera</title><path d="M4,4H7L9,2H15L17,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z" /></svg>
            </v-col>
            <v-col cols="7" class="d-flex align-center pb-0">
                <h1>ご自由にご撮影ください<br><small>Photography allowed</small></h1>
            </v-col>
            <v-col cols="1" class="pb-0"></v-col>
        </v-row>
        <v-row align-center class="mt-0 pt-0">
            <v-col cols="1" class="pt-0"></v-col>
            <v-col cols="10" class="pt-0">
                <v-alert
                text
                prominent
                class="rounded-lg mt-0"
                type="error"
                icon="mdi-camera-off"
                >
                展示員及び他のお客様の顔の含まれる写真のSNS等のインターネット上の共有を固く禁じます。<br>
                Sharing of photos containing the faces of exhibitors and other visitors on social networking sites and other internet sites is strictly prohibited.
                </v-alert>
            </v-col>
            <v-col cols="1" class="pt-0"></v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    
};
</script>
<style scoped>
    .contents{
        margin-top:18vh;
    }
    .svg{
        height:100%; 
        width:100%;
        fill:#00897B;
    }
    .svg2{
        height:15vh; 
        width:15vh;
        fill:#FB8C00;
    }
    h1{
        font-size: 75px;
    }
    h2{
        font-size: 55px;
    }
    .statusshow{
        font-size: 45px;
        font-weight: bolder;
    }
    .statusshoweng{
        font-size: 25px;
        font-weight: bolder;
    }
    .statusreason{
        font-weight: bolder;
    }
</style>