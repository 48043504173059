<template>
    <div class="contents ma-3">
        <v-row>
            <v-card class="pa-4 ma-3" width="100%">
                <v-row class="mr-1">
                    <v-col cols="2">
                        <div class="ml-10">
                            <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>arrow-right</title><path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" /></svg>
                            
                        </div>
                    </v-col>
                    <v-col cols="10">
                        <v-row>
                            <v-col cols="6">
                                <v-card 
                                    class="ma-4 pa-4 mt-4"
                                    color="orange darken-1"
                                    width="100%"
                                    dark
                                    >
                                        <v-row>
                                            <v-col cols="2" class="ml-6"><p class="platnum">1</p></v-col>
                                            <v-col cols="9">
                                                <p class="platname">湘南レイアウト</p>
                                                <p class="platnamesub">東海道線・横須賀線・根岸線</p>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            <v-col cols="6"></v-col>
                        </v-row>
                        
                    </v-col>
                </v-row>
            </v-card>
        </v-row>
        <v-row>
            <v-card class="pa-4 ma-3" width="100%">
                <v-row class="mr-1">
                    <v-col cols="2">
                        <div class="ml-10">
                            <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>arrow-right</title><path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" /></svg>
                            <v-card label class="ma-4 ml-8 mb-1 pa-2" width="8vw" dark color="black"><span class="titlechip">108教室</span></v-card>
                        </div>
                    </v-col>
                    <v-col cols="10">
                        <v-row>
                            <v-col cols="6">
                                <v-card 
                                class="ma-4 pa-4 mt-4"
                                color="light-blue darken-1"
                                width="100%"
                                dark
                                >
                                    <v-row>
                                        <v-col cols="2" class="ml-6"><p class="platnum">2</p></v-col>
                                        <v-col cols="9">
                                            <p class="platname">四国レイアウト</p>
                                            <p class="platnamesub">JR予讃線・伊予鉄市内電車</p>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                            <v-col cols="6">
                                <v-card 
                                class="ma-4 pa-4 mt-4 mr-2"
                                color="indigo darken-1"
                                width="100%"
                                dark
                                >
                                    <v-row>
                                        <v-col cols="2" class="ml-6"><p class="platnum">3</p></v-col>
                                        <v-col cols="9">
                                            <p class="platname">京津レイアウト</p>
                                            <p class="platnamesub">Nゲージ運転体験コーナー</p>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-card 
                                class="ma-4 pa-4 mt-4"
                                color="teal darken-1"
                                width="100%"
                                dark
                                >
                                    <v-row>
                                        <v-col cols="2" class="ml-6"><p class="platnum">4</p></v-col>
                                        <v-col cols="9">
                                            <p class="platname">LED方向幕</p>
                                            <p class="platnamesub">記念撮影・展示コーナー</p>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                            <v-col cols="6">
                                <div 
                                class="ma-4 pa-4 mt-4 mr-2"
                                width="100%"
                                light
                                >
                                    <v-row>
                                        <v-col cols="12">
                                            <p class="platname">きっぷうりば</p>
                                            <p class="platnamesub">部誌・クリアファイル販売ブース</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
            
        </v-row>
    </div>
</template>

<script>
export default {
    
};
</script>
<style scoped>
    .platnum{
        font-size:80px; 
        margin-bottom:0;
    }
    .platnamesub{
        font-size:30px; 
        margin-bottom:0;
        font-weight:medium;
    }
    .platname{
        font-size:50px; 
        font-weight: bold;
        margin-bottom:0;
    }
    .titlechip{
        font-size:30px; 
        font-weight: bold;
    }
    .svg{
        height:12vw; 
        width:12vw;
        fill:#000;
        margin:0 auto;
    }
    h1{
        font-size: 75px;
    }
    h2{
        font-size: 55px;
    }
    .statusshow{
        font-size: 45px;
        font-weight: bolder;
    }
    .statusshoweng{
        font-size: 25px;
        font-weight: bolder;
    }
    .statusreason{
        font-weight: bolder;
    }
</style>