<template>
    <div>
        <v-app id="inspire">
            <v-main v-if="!loading">
            <v-row align-content="center" class="text-center" height="100vh" style="height:100vh;">
                <v-col
                cols="12"
                >
                    <v-progress-circular
                        :size="70"
                        :width="7"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </v-col>
                
            </v-row>
                
            </v-main>
            <v-main v-if="loading">
                <v-row
                no-gutters
                >
                    <v-col
                    cols="12"
                    >
                        <v-form 
                        ref="form3"
                        v-model="valid"
                        lazy-validation
                        >
                            <v-simple-table 
                            class="pa-1"
                            fixed-header
                            height="83vh"
                             dense
                            >
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            ローテ名
                                        </th>
                                        <th class="text-left" v-for="(status,index) in gottenTimeNum" :key="index">
                                            {{gottenTime[index]}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(member, index) in data" :key="index">
                                        <th scope="row" style="padding:5px;line-height:10px;height:22px;"><h4>{{ gottenFrame[index] }}</h4></th>
                                        <td v-for="(status,indexx) in gottenTimeNum" :key="indexx" style="padding:1px;line-height:7px;height:23px;">
                                            <div group="ITEMS" :list="data[index][indexx]">
                                                <v-card v-for="item,indexxx in data[index][indexx]" class="pa-1" :key="indexxx" light  :class="gradeColor(data[index][indexx][indexxx][2])">
                                                    <h4>{{data[index][indexx][indexxx][0]}}</h4>
                                                </v-card>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-form>
                    </v-col>
                </v-row>
            </v-main>
        </v-app>
    </div>
</template>
<script>

import axios from 'axios'
export default {
    props: {
        eventid:String,
        day:String,
    },
    data() {
        return {
            data:{},
            daynum:0,
            loading:false,
            gottenTime:[],
            gottenTimeNum:16,
            valid: true,
            gottenFrame:[],
            gottenFrameNum:0,
            gottenFrameCate:[],
            gottenCate:[],
            gottenCateNum:0,
            gottenRotenum:[],
        };
    },
    created() {
       this.statusCheck();
       this.getTime();
       this.getFrame();
       this.getFrameCate();
       this.getCate();
    },
    methods: {
        statusCheck(){
            axios.get('api/fr/event/'+this.eventid+'/rote/'+this.day)
                .then((res) => {
                    this.data=res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getLike(){
            axios.get('api/fr/event/'+this.eventid+'/member/like/edit')
                .then((res) => {
                    this.gottenLike=res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getTime(){
            axios.get('api/fr/event/'+this.eventid+'/time')
                .then((res) => {
                    this.gottenTime=res.data;
                    this.gottenTimeNum=res.data.length;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getFrame(){
            axios.get('api/fr/event/'+this.eventid+'/frame/'+this.day)
                .then((res) => {
                    this.gottenFrame=res.data;
                    this.gottenFrameNum=res.data.length;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getFrameCate(){
            axios.get('api/fr/event/'+this.eventid+'/frame/'+this.day+'/cate')
                .then((res) => {
                    this.gottenFrameCate=res.data;
                    this.loading=true
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getCate(){
            axios.get('api/fr/event/'+this.eventid+'/cate')
                .then((res) => {
                    this.gottenCate=res.data;
                    this.gottenCateNum=res.data.length;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        gradeColor(grade){
            if (grade=="2018") {
                return "deep-purple lighten-2";
            }else if (grade=="2019"){
                return "light-blue lighten-2";
            }else if (grade=="2020"){
                return "yellow lighten-2";
            }else if (grade=="2021"){
                return "green lighten-2";
            }else if (grade=="2022"){
                return "pink lighten-2";
            }else{
                return "green";
            }
        },
    }
};
</script>
<style scoped>
    h4{
        font-size:10px;
        font-weight:bold;
    }
</style>