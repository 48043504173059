<template>
    <div
     >  <v-app id="inspire">
            <v-card
            class="indigo darken-2 pa-6"
            tile
            >
                <v-row>
                        <v-col
                        cols="12"
                        md="2"
                        >   
                            <leftMenu/>
                        </v-col>
                        <v-col
                        cols="12"
                        md="10">
                              <v-card
                            elevation="12"
                            
                            >
                                <v-form class="pa-10"
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation
                                    
                                    >
                                    <h3>運行情報更新</h3>
                                    <h4>湘南系統</h4>
                                    <v-row
                                    >
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-select
                                                v-model="maindata[0][0]"
                                                item-text="[0]"
                                                :items="infoselect1"
                                                label="運行情報"
                                                return-object
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="8"
                                        >   
                                            <v-text-field
                                                v-model="maindata[0][1]"
                                                :counter="50"
                                                :rules="reasonRules"
                                                label="概況"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <h4>四国系統</h4>
                                    <v-row
                                    >
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-select
                                                v-model="maindata[1][0]"
                                                item-text="[0]"
                                                :items="infoselect1"
                                                label="運行情報"
                                                return-object
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="8"
                                        >   
                                            <v-text-field
                                                v-model="maindata[1][1]"
                                                :counter="50"
                                                :rules="reasonRules"
                                                label="概況"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <h4>一畳系統</h4>
                                    <v-row
                                    >
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-select
                                                v-model="maindata[2][0]"
                                                item-text="[0]"
                                                :items="infoselect2"
                                                label="運行情報"
                                                return-object
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="8"
                                        >   
                                            <v-text-field
                                                v-model="maindata[2][1]"
                                                :counter="50"
                                                :rules="reasonRules"
                                                label="概況"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-btn
                                        :disabled="!valid"
                                        color="indigo darken-2"
                                        dark
                                        class="mr-4"
                                        @click="submit(1)"
                                    >
                                        Create
                                    </v-btn>
                                </v-form>
                                <v-form class="pa-10"
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation
                                    
                                    >
                                    <h3>在庫情報更新</h3>
                                    <h4>レールサイド</h4>
                                    <v-row
                                    >
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-select
                                                v-model="subdata[0][0]"
                                                item-text="[0]"
                                                :items="infoselect3"
                                                label="運行情報"
                                                return-object
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="8"
                                        >   
                                            <v-text-field
                                                v-model="subdata[0][1]"
                                                :counter="50"
                                                :rules="reasonRules"
                                                label="概況"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <h4>クリアファイル</h4>
                                    <v-row
                                    >
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-select
                                                v-model="subdata[1][0]"
                                                item-text="[0]"
                                                :items="infoselect3"
                                                label="運行情報"
                                                return-object
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="8"
                                        >   
                                            <v-text-field
                                                v-model="subdata[1][1]"
                                                :counter="50"
                                                :rules="reasonRules"
                                                label="概況"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <h4>パスケース</h4>
                                    <v-row
                                    >
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-select
                                                v-model="subdata[2][0]"
                                                item-text="[0]"
                                                :items="infoselect3"
                                                label="運行情報"
                                                return-object
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="8"
                                        >   
                                            <v-text-field
                                                v-model="subdata[2][1]"
                                                :counter="50"
                                                :rules="reasonRules"
                                                label="概況"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-btn
                                        :disabled="!valid"
                                        color="indigo darken-2"
                                        dark
                                        class="mr-4"
                                        @click="submit(2)"
                                    >
                                        Create
                                    </v-btn>
                                </v-form>
                            </v-card>  
                        </v-col>
                </v-row>
                
            </v-card>
            <v-dialog
            v-model="dialog"
            width="500"
            ><v-card>
                <v-card-title class="text-h5 grey lighten-2">
                API通信エラー
                </v-card-title>
                <v-card-text>
                {{error}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                >
                    close
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-app>
       
            
    </div>
</template>
<script>
import axios from 'axios'
import leftMenu from "./parts/leftMenu";
export default {
    components: {leftMenu},
    data() {
        return {
            error:"",
            dialog:false,
            drawer:null,
            data:[],
            maindata:[],
            subdata:[],
            valid: true,
            reasonRules: [
                v => !!v || '「概況」は必須です',
                v => (v && v.length <= 200) || 'Wallet must be less than 200 characters',
            ],
            infoselect1:[
                ['平常運行','1'],
                ['遅れ','2'],
                ['ダイヤ乱れ','2'],
                ['運転見合わせ','3'],
                ['未開通','3'],
            ],
            infoselect2:[
                ['混雑','2'],
                ['平常運行','1'],
                ['一時停止中','3'],
                ['終了','3'],
            ],
            infoselect3:[
                ['販売中','1'],
                ['在庫僅少','2'],
                ['売り切れ','3'],
                ['中止','3'],
            ],
        };
    },
    mounted() {
        this.statusCheack();
    },
    methods: {
        statusCheack(){
            this.getData(1)
            this.getData(2)
        },
        getData(dataid){
            axios.get('api/home/datainfo/'+dataid)
                .then((res) => {
                    if(dataid==1){
                        this.maindata=res.data
                    }else if(dataid==2){
                        this.subdata=res.data
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.error=error
                    this.dialog=true
                });
        },
        submit(dataid){
            if(dataid==1){
                this.data=this.maindata
            }else{
                this.data=this.subdata
            }
            axios.post('api/home/datainfo/'+dataid, {"data":this.data})
                .then(() => {
                })
                .catch(error => {
                    console.log(error);
                    this.error=error
                    this.dialog=true
                });
        },
    }
};
</script>
<style>
    .v-application{
        font-family: 'Outfit','Noto Sans JP', sans-serif !important;
    }
    .h4{
        vertical-align: middle;
    }
</style>