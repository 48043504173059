<template>
    <div>  
        <v-app id="inspire">
            <div>
                <div>
                    <div class="maincontents" style="height:100vh;">
                           <statusshow></statusshow>
                    </div>
                    <div class="subcontents" style="height:15vh;width:98vw;">
                           <welcomeshow/>
                    </div>
                </div>
            </div>
        </v-app>
    </div>
</template>
<script>
import axios from 'axios'
//import draggable from 'vuedraggable';
import statusshow from "./parts/sign2";
import welcomeshow from "./parts/welcomeshow";
export default {
    data() {
        return {
            vehicle:[[],[],[]],
            eventid:"1",
            daynum:"1",
        };
    },
    components: {statusshow,welcomeshow},
    mounted() {
        axios.get('api/vm/cars/active')
                .then((res) => {
                    this.vehicle=res.data
                })
                .catch(error => {
                    console.log(error)
                });
    },
    methods: {
        
    }
};
</script>
<style scoped>
.maincontents{
        position: relative;
    }
    .subcontents{
        position: absolute;
        top: 82vh;
        left: 0;
    }
</style>