import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import axios from 'axios'
import LoginComponent from "./components/LoginComponent";
import HomeComponent from "./components/HomeComponent";
import MediaUpComponent from "./components/MediaUpComponent";
import VManageComponent from "./components/VManageComponent";
import VManageAlphaComponent from "./components/VManageAlphaComponent";
import FesRFesListComponent from "./components/FesRFesListComponent";
import FesRMembersListComponent from "./components/FesRMembersListComponent";
import FesRAddEventComponent from "./components/FesRAddEventComponent";
import FesREventSettingComponent from "./components/FesREventSettingComponent";
import FesREventDetailComponent from "./components/FesREventDetailComponent";
import FesREventLiceChangeComponent from "./components/FesREventLiceChangeComponent";
import FesREventLikeChangeComponent from "./components/FesREventLikeChangeComponent";
import FesREventSchChangeComponent from "./components/FesREventSchChangeComponent";
import FesREventRoteEditComponent from "./components/FesREventRoteEditComponent";
import FesRFormEnterComponent from "./components/FesRFormEnterComponent";
import FesREventFormsComponent from "./components/FesREventFormsComponent";
import DisplayComponent from "./components/DisplayComponent";
import DisplayDebugComponent from "./components/DisplayDebugComponent";

//const backurldata= 'http://localhost:80/afes76/laravel/public/'
const backurldata= 'https://afes76.knomo0316.net/public/'
axios.defaults.baseURL = backurldata
Vue.prototype.$backurl = backurldata
axios.defaults.withCredentials = true;
Vue.config.productionTip = false

Vue.use(VueRouter);
Vue.use(Vuetify);

const router = new VueRouter({
  routes: [
     {
         path: "/login",
         name: "Login",
         component: LoginComponent,
         meta:{
             isPublic:true,
             isLoginPage:true,
         }
     },
    {
        path: "/home",
        name: "Home",
        component: HomeComponent,
        props: true
    },
    {
        path: "/display",
        name: "Display",
        component: DisplayComponent,
        props: true
    },
    {
        path: "/display/debug",
        name: "DisplayDebug",
        component: DisplayDebugComponent,
        props: true
    },
    {
        path: "/mediaup",
        name: "MediaUp",
        component: MediaUpComponent,
        props: true
    },
    {
        path: "/vmanage/syonan",
        name: "VManageSyonan",
        component: VManageComponent,
        props: true
    },
    {
        path: "/vmanage/shikoku",
        name: "VManageShikoku",
        component: VManageAlphaComponent,
        props: true
    },
    {
        path: "/fesrotainer",
        name: "fesrotainer.home",
        component: FesRFesListComponent,
        meta:{
            isAdmin:true,
        }
    },
    {
        path: "/fesrotainer/members",
        name: "fesrotainer.memberslist",
        component: FesRMembersListComponent,
        meta:{isAdmin:true,}
    },
    {
        path: "/fesrotainer/events/add",
        name: "fesrotainer.eventsadd",
        component: FesRAddEventComponent,
        meta:{isAdmin:true,}
    },
    {
        path: "/fesrotainer/events/:eventid/firstsettings",
        name: "fesrotainer.eventssetting",
        component: FesREventSettingComponent,
        props: true,
    },
    {
        path: "/fesrotainer/events/:eventid",
        name: "fesrotainer.eventsdetail",
        component: FesREventDetailComponent,
        props: true,
    },
    {
        path: "/fesrotainer/events/:eventid/licechange",
        name: "fesrotainer.eventslicechange",
        component: FesREventLiceChangeComponent,
        props: true,
    },
    {
        path: "/fesrotainer/events/:eventid/likechange",
        name: "fesrotainer.eventslikechange",
        component: FesREventLikeChangeComponent,
        props: true,
    },
    {
        path: "/fesrotainer/events/:eventid/schchange/:day",
        name: "fesrotainer.eventsschchange",
        component: FesREventSchChangeComponent,
        props: true,
    },
    {
        path: "/fesrotainer/events/:eventid/rote/:day",
        name: "fesrotainer.eventsroteedit",
        component: FesREventRoteEditComponent,
        props: true,
    },
    {
        path: "/fesrotainer/events/:eventid/forms",
        name: "fesrotainer.eventform",
        component: FesREventFormsComponent,
        props: true,
    },
    {
        path: "/public/fesr/enterform/:formid",
        name: "fesrotainer.formenter",
        component: FesRFormEnterComponent,
        props: true,
        meta:{
            isPublicPage:true,
        }
    },
  ]
});
var user={};
 router.beforeEach((to,from,next)=>{
    if(to.matched.some(record=>record.meta.isPublicPage)){
        
        next();
    }else if(to.matched.some(record=>!record.meta.isPublic)){
       axios.get("api/user")
       .then(function(response){
           user= response.data;
           next();
       })
       .catch(function(error){
           if(error.response.status=="401"){
               next({path:'/login',query:{redirect:to.fullPath}});
           }else{
               console.log(error);
           }
       });
       //next({path:'/login',query:{redirect:to.fullPath}});
    }else if(to.matched.some(record=>!record.meta.isloginPage)){
        axios
          .get("api/user")
          .then(function(){
            next({path:'/home'});
          })
          .catch(function(error){
            if(error.response.status=="401"){
                next();
            }else{
                console.log(error);
                next();
            }
        });
    }
    else{
        next();
    }
});
new Vue({
  router,
  vuetify: new Vuetify(),
  render: h => h(App),
  data:{
    user:user,
},
}).$mount('#app')
