<template>
    <div>
        <v-app id="inspire">
            <v-card
            class="indigo darken-2 pa-6"
            tile
            >
            <v-row>
                <v-col
                cols="12"
                md="2"
                >
                    <leftMenu></leftMenu>
                </v-col>
                <v-col
                cols="12"
                md="8"
                >
                    <v-card elevation="12" class="pa-2">
                        <h2 class="pa-10 pb-1">イベント作成</h2>
                        <v-stepper v-model="e1" class="ma-4">
                        <v-stepper-header>
                            <v-stepper-step
                                :complete="e1 > 1"
                                step="1"
                            >イベント作成</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step
                                :complete="e1 > 2"
                                step="2"
                            >ローテ種別設定</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step
                                :complete="e1 > 3"
                                step="3"
                            >ローテ個別設定</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step
                                :complete="e1 > 4"
                                step="4"
                            >時間設定</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step step="5">メンバー設定</v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-form 
                                    ref="form1"
                                    v-model="valid"
                                    lazy-validation
                                    >
                                    <v-text-field
                                        v-model="event.name"
                                        :counter="20"
                                        :rules="nameRules"
                                        label="イベント名"
                                        required
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="event.daynum"
                                        :counter="1"
                                        :rules="daynumRules"
                                        label="長さ"
                                        required
                                    ></v-text-field>
                                </v-form>
                                <v-btn
                                color="primary"
                                @click="addEvent"
                                >Next</v-btn>
                            </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-card>
                </v-col>
                <v-col
                cols="12"
                md="2"
                >
                <rightMenu/>
                </v-col>
            </v-row>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import leftMenu from "./parts/leftMenu";
import rightMenu from "./parts/rightMenu";
import axios from 'axios'
export default {
    components: { leftMenu,rightMenu},
    data() {
        return {
            drawer:null,
            e1: 1,
            members:"",
            file:null,
            valid: true,
            event:{},
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 20) || 'Name must be less than 20 characters',
            ],
            daynumRules:[
                v => !!v || 'Travel-length is required',
                v => (v && v.length <= 1) || 'travel-length must be less than 1 characters',
            ],
        };
    },
    methods: {
        addEvent(){
            this.$refs.form1.validate();
            axios.post('api/fr/event', this.event)
                .then((res) => {
                    this.event="";
                    this.event=res.data;
                    console.log(res.data);
                    this.$router.push({name: 'fesrotainer.eventssetting',params: {eventid: String(this.event.id)}});
                })
                .catch(error => {
                    console.log(error);
                });
        },
    }
};
</script>