<template>
    <div class="contents ma-3">
        <v-row>
            <v-card class="pa-4 ma-3" width="100%">
                <v-row class="mr-1">
                    <v-col cols="2">
                        <div class="ml-10">
                            <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>arrow-right</title><path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" /></svg>
                            
                        </div>
                    </v-col>
                    <v-col cols="10">
                        <v-row>
                            <v-col cols="6">
                                <v-card 
                                class="ma-4 pa-4 mt-4"
                                color="light-blue darken-1"
                                width="100%"
                                dark
                                >
                                    <v-row>
                                        <v-col cols="2" class="ml-6"><p class="platnum">2</p></v-col>
                                        <v-col cols="9">
                                            <p class="platname">四国レイアウト</p>
                                            <p class="platnamesub">JR予讃線・伊予鉄市内電車</p>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                            <v-col cols="6">
                                <v-card 
                                class="ma-4 pa-4 mt-4 mr-2"
                                color="indigo darken-1"
                                width="100%"
                                dark
                                >
                                    <v-row>
                                        <v-col cols="2" class="ml-6"><p class="platnum">3</p></v-col>
                                        <v-col cols="9">
                                            <p class="platname">京津レイアウト</p>
                                            <p class="platnamesub">Nゲージ運転体験コーナー</p>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        
                    </v-col>
                </v-row>
            </v-card>
        </v-row>
        <v-row>
            <v-card class="pa-4 ma-3" width="100%" color="yellow darken-1">
                <v-row class="mr-1">
                    <v-col cols="2">
                        <div class="ml-10">
                            <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>arrow-left</title><path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" /></svg>
                        </div>
                    </v-col>
                    <v-col cols="10">
                        <v-row class="pt-4">
                            <v-col>
                                <p class="areaname mb-0 pl-5">展示教室出口</p>
                                <p class="titlechip mb-0 pl-5">Main exhibition exit</p>
                            </v-col>
                            
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="pt-0 mt-0">
                                <div 
                                class="ma-4 pa-4 mt-4 mr-2"
                                width="100%"
                                light
                                >
                                    <v-row>
                                        <v-col cols="12">
                                            <p class="platname">トイレ</p>
                                            <p class="platnamesub">円形トイレ(左)・図書館棟前トイレ(右)</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                            <v-col cols="6" class="pt-0 mt-0">
                                <div 
                                class="ma-4 pa-4 mt-4 mr-2"
                                width="100%"
                                light
                                >
                                    <v-row>
                                        <v-col cols="12">
                                            <p class="platname">中央階段</p>
                                            <p class="platnamesub">中庭ステージ・109-112教室・2F・3F</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
            
        </v-row>
    </div>
</template>

<script>
export default {
    
};
</script>
<style scoped>
    .platnum{
        font-size:80px; 
        margin-bottom:0;
    }
    .platnamesub{
        font-size:30px; 
        margin-bottom:0;
        font-weight:medium;
    }
    .areaname{
        font-size:70px; 
        font-weight: bold;
        margin-bottom:0;
    }
    .platname{
        font-size:50px; 
        font-weight: bold;
        margin-bottom:0;
    }
    .titlechip{
        font-size:30px; 
        font-weight: bold;
    }
    .svg{
        height:12vw; 
        width:12vw;
        fill:#000;
        margin:0 auto;
    }
    h1{
        font-size: 75px;
    }
    h2{
        font-size: 55px;
    }
    .statusshow{
        font-size: 45px;
        font-weight: bolder;
    }
    .statusshoweng{
        font-size: 25px;
        font-weight: bolder;
    }
    .statusreason{
        font-weight: bolder;
    }
</style>