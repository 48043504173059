<template>
    <div>
        <v-app id="inspire">
            <v-card
            class="indigo darken-2 pa-6"
            tile
            >
            <v-row>
                <v-col
                cols="12"
                md="2"
                >
                    <leftMenu></leftMenu>
                </v-col>
                <v-col
                cols="12"
                md="8"
                >
                    <v-card elevation="12">
                        <h2 class="pa-10 pb-1">イベントの一覧</h2>
                        <v-simple-table class="pa-10">
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    Id
                                </th>
                                <th class="text-left">
                                    name
                                </th>
                                <th class="text-left">
                                    daynum
                                </th>
                                <th class="text-left"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(travel, index) in travels" :key="index">
                                    <th scope="row">{{ travel.id }}</th>
                                    <td>{{ travel.name }}</td>
                                    <td>{{ travel.daynmu }}</td>
                                    <td>
                                        <router-link v-bind:to="{name: 'fesrotainer.eventsdetail', params: {eventid: travel.id }}">
                                            <v-btn
                                                color="indigo"
                                                class="mr-4"
                                                dark
                                            >
                                                Show Detail
                                            </v-btn>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
                <v-col
                cols="12"
                md="2"
                >
                <rightMenu/>
                </v-col>
            </v-row>
            </v-card>
        </v-app>
    </div>
</template>

<script>
import leftMenu from "./parts/leftMenu";
import rightMenu from "./parts/rightMenu";
import axios from 'axios'
export default {
    components: { leftMenu,rightMenu},
    data() {
        return {
            drawer:null,
            travels:"",
        };
    },
    mounted() {
       this.getTravels();
    },
    methods: {
        getTravels(){
           axios.get('api/fr/event')
                .then((res) => {
                    this.travels = res.data;
                }); 
        }
    }
};
</script>