<template>
    <div>
        <v-app id="inspire">

                <v-card
                class="indigo lighten-2 pa-6"
                tile
                >
                <v-row>
                    <v-col
                    cols="12"
                    md="3"
                    ></v-col>
                    <v-col
                    cols="12"
                    md="6"
                    >
                    <div v-if="send">
                        <v-card class="pa-6 my-3">
                            <h1><strong>ローテ希望入力フォーム</strong></h1>
                            <p>ローテ希望をコチラのフォームへ入力し、送信してください。修正がある場合は、「修正済みの希望」をすべて入力し、送信してください。お問い合わせは執行部LINEまで</p>
                        </v-card>
                        <v-card class="pa-6 my-3">
                            <h4>{{error}}</h4>
                            
                        </v-card>
                        <div class="indigo lighten-2 " style="height:100vh;"></div>
                    </div>
                    <v-form
                    ref="form"
                    v-model="valid"
                    v-if="!send"
                    >
                        <v-card class="pa-6 my-3">
                            <h1><strong>ローテ希望入力フォーム</strong></h1>
                            <p>ローテ希望をコチラのフォームへ入力し、送信してください。修正がある場合は、「修正済みの希望」をすべて入力し、送信してください。お問い合わせは執行部LINEまで</p>
                            <p><strong>「No Data」などと表示される場合はリロードをお願いします。</strong></p>
                        </v-card>
                        <v-card class="pa-6 my-3">
                            <h3>基本情報</h3>
                            <v-select
                                v-model="mygrade"
                                item-text="label"
                                item-value="data"
                                label="学年"
                                :items="infoselect1"
                                :rules="reasonRules"
                            ></v-select>
                            <v-select
                                v-model="myclass"
                                item-text="label"
                                item-value="data"
                                label="クラス"
                                :items="infoselect2"
                                :rules="reasonRules"
                            ></v-select>
                            <v-select
                                v-model="mynumber"
                                item-text="label"
                                item-value="data"
                                label="番号"
                                :items="infoselect3"
                                :rules="reasonRules"
                            ></v-select>
                            <v-text-field
                            label="名字"
                            :rules="reasonRules"
                            v-model="mynamea"
                            hint="「佐藤」"
                            ></v-text-field>
                            <v-text-field
                            label="名前"
                            :rules="reasonRules"
                            v-model="mynameb"
                            hint="「修治」"
                            ></v-text-field>
                        </v-card>
                        <v-card class="pa-6 my-3">
                            <h3>ローテ種別希望情報</h3>
                            <v-select
                                v-model="mylike"
                                item-text="label"
                                item-value="data"
                                label="ローテ種別"
                                :items="infoselect4"
                                required
                                multiple
                                attach
                                chips
                                hint="全ての入りたい所にチェックを入れてください"
                            ></v-select>
                        </v-card>
                        <v-card class="pa-6 my-3" v-if="tablefind">
                            <h3>スケジュール情報</h3>
                            <v-simple-table 
                                class="pa-1"
                                fixed-header
                                
                            >
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            時間
                                        </th>
                                        <th class="text-left" v-for="n in this.event.daynum" :key="n">
                                            {{n}}日目
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="nn in gottenTime.length" :key="nn">
                                        <th scope="row">{{gottenTime[nn-1]}}</th>
                                        <td v-for="nnn in event.daynum" :key="nnn">
                                            <v-checkbox
                                            v-model="infodata5[nnn-1][nn-1]"
                                            ></v-checkbox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td v-for="nnnn in event.daynum" :key="nnnn">
                                            <v-btn
                                                color="indigo darken-2"
                                                dark
                                                class="mr-4"
                                                @click="maketrue(nnnn)"
                                            >
                                               全てチェック
                                            </v-btn>
                                        </td>
                                    </tr>
                                     <tr>
                                        <th></th>
                                        <td v-for="nnnn in event.daynum" :key="nnnn">
                                            <v-btn
                                                color="pink darken-2"
                                                dark
                                                class="mr-4"
                                                @click="makefalse(nnnn)"
                                            >
                                               全てクリア
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                            <p>ローテに入れる時間にチェックを入れてください</p>
                        </v-card>
                        <v-card class="pa-6 my-3">
                            <h3>備考</h3>
                            <v-textarea
                            v-model="mydetail"
                            label="詳細希望"
                            hint="細かい希望(「1日目は9:00-9:30に湘南、他は無し」「2日目は四国多め、湘南2つのみ」「フルローテ希望」等)"
                            ></v-textarea>
                        </v-card>
                        <v-card class="pa-6 my-3">
                            <h3>注意事項</h3>
                            <p>スケジュールの希望については最大限尊重できるよう調整しますが、ローテ種別やその他の細かい希望については一部ご希望に添えない場合がございます。予めご了承願います。</p>
                            <p>ローテ数、特に運転ローテについては日々の作業貢献度を基に、プラスマイナス要因(+：PV作成/-：作業妨害・販売講習会不参加)を加味して決定します。</p>
                            <p>入力期限後の変更及びリクエストは直接執行部LINEまでお申し出ください。</p>
                        </v-card>
                        <v-btn
                            :disabled="!valid"
                            color="indigo darken-2"
                            dark
                            class="mr-4"
                            @click="submit"
                        >
                            Create
                        </v-btn>
                    </v-form>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    ></v-col>
                </v-row>
            </v-card>
            <v-dialog
            v-model="dialog"
            width="290"
            persistent
            ><v-card>
                <v-card-title class="text-h5">
                不適切なパラメーター
                </v-card-title>
                <v-card-text>
                    リンクに記載されたフォームidが不適切なため入力を継続することができません。お問い合わせは恐れ入りますが管理者までお願いいたします。
                </v-card-text>
            </v-card>
            </v-dialog>
            <v-dialog
            v-model="dialog2"
            width="290"
            persistent
            ><v-card>
                <v-card-title class="text-h5">
                パラメーターエラー
                </v-card-title>
                <v-card-text>
                    恐れ入りますがリロード(再読み込み)をお願いします。<br>
                    この画面は何回も出る場合があります。
                </v-card-text>
            </v-card>
            </v-dialog>
        </v-app>
       
            
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            dialog:false,
            dialog2:false,
            send:false,
            mygrade:"",
            error:"",
            mylike:"",
            myclass:"",
            mynumber:"",
            mydetail:"",
            mynamea:"",
            mynameb:"",
            event:[],
            valid:false,
            tablefind:false,
            gottenCate:"",
            gottenTime:["9:00-9:30", "9:30-10:00"],
            infoselect1:[],
            infoselect2:[],
            infoselect3:[],
            infoselect4:[],
            infodata5:[],
            reasonRules: [
                v => !!v || 'この項目は必須です',
            ],
        };
    },
    props: {
        formid:String,
    },
    methods: {
        statusCheack(){
            axios
            .get('sanctum/csrf-cookie')
            .then(function(){
                axios.post('api/fr/event/form/find', {"data":this.formid})
                .then((res) => {
                    this.event=res.data;
                    this.getCate();
                    this.getTime();
                })
                .catch(error => {
                    if(error.response.status==598){
                        this.dialog=true
                    }else{
                        this.dialog2=true
                    }
                    console.log(error);
                });
            }.bind(this))
            .catch(function(error){
                console.log(error);
                this.dialog2=true
            }.bind(this))
            
        },
        getCate(){
            axios.get('api/fr/event/'+this.event.id+'/cate')
                .then((res) => {
                    this.gottenCate=res.data;
                    this.makeinfo4();
                    if(this.gottenCate==[]){
                        this.dialog2=true
                    }
                })
                .catch(error => {
                    this.dialog2=true
                    console.log(error);
                });
        },
        getTime(){
            axios.get('api/fr/event/'+this.event.id+'/time')
                .then((res) => {
                    this.gottenTime=res.data;
                    this.makeinfo5();
                    if(this.gottenTime==[]){
                        this.dialog2=true
                    }
                })
                .catch(error => {
                    this.dialog2=true
                    console.log(error);
                });
        },
        makeinfo1(){
            var info1=[]
            for(var i=2019;i<2024;i++){
                info1.push({label:i+'年入学',data:i})
            }
            this.infoselect1=info1
       },
        makeinfo2(){
            var info2=[]
            for(var i=1;i<8;i++){
                info2.push({label:i+'組',data:i})
            }
            this.infoselect2=info2
       },
       makeinfo3(){
            var info3=[]
            for(var i=1;i<45;i++){
                info3.push({label:i+'番',data:i})
            }
            this.infoselect3=info3
       },
       makeinfo4(){
            var info4=[]
            for(var i=0;i<this.gottenCate.length;i++){
                info4.push({label:this.gottenCate[i],data:i})
            }
            this.infoselect4=info4
       },
       makeinfo5(){
            var info5=[]
            for(var i=0;i<this.event.daynum;i++){
                var infodata=[]
                for(var ii=0;ii<this.gottenTime.length;ii++){
                    infodata.push(false)
                }
                info5.push(infodata)
            }
            this.infodata5=info5
            this.tablefind=true
       },
       maketrue(day){
            var info5=[]
            for(var ii=0;ii<this.gottenTime.length;ii++){
                info5.push(true)
            }
            this.infodata5[day-1]=info5
            this.tablefind=false
            this.tablefind=true
       },
       makefalse(day){
            var info5=[]
            for(var ii=0;ii<this.gottenTime.length;ii++){
                info5.push(false)
            }
            this.infodata5[day-1]=info5
            this.tablefind=false
            this.tablefind=true
       },
       submit(){
        if(this.mydetail==""){
            this.mydetail="希望無し(自動)"
        }
            axios.post('api/fr/event/form/'+this.event.id, {
                "mygrade":this.mygrade,
                "myclass":this.myclass,
                "mynumber":this.mynumber,
                "mynamea":this.mynamea,
                "mynameb":this.mynameb,
                "catedata":this.mylike,
                "schdata":this.infodata5,
                "notedata":this.mydetail,
                "eventid":this.event.id
            })
                .then(() => {
                    this.send=true
                    this.error="正常にリクエストは送信されました。"
                })
                .catch(error => {
                    console.log(error);
                    this.send=true
                    this.error=error
                });
        },
    },
    mounted() {
        this.statusCheack();
        this.makeinfo1();
        this.makeinfo2();
        this.makeinfo3();
    },
};
</script>
<style>
    .v-application{
        font-family: 'Outfit','Noto Sans JP', sans-serif !important;
    }
    .h4{
        vertical-align: middle;
    }
</style>