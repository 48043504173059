<template>
    <div class="contents">
        <v-row class="mb-0 pb-0 mt-8">
            <v-col cols="1" class="pb-0"></v-col>
            <v-col cols="3" class="pb-0">
                <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>hand-back-left-off</title><path d="M22.11 21.46L2.39 1.73L1.11 3L3.21 5.1C3.09 5.29 3 5.5 3 5.75V16C3 20.42 6.58 24 11 24C13.93 24 16.58 22.39 17.97 19.86L20.84 22.73L22.11 21.46M5.5 12V7.39L6.5 8.39V12H5.5M10 12H9V10.89L10 11.89V12M16 12.8L13.5 10.3V3.25C13.5 2.56 14.06 2 14.75 2S16 2.56 16 3.25V12.8M19.5 16.28L17.16 13.96L18.16 11.47C18.38 10.92 18.84 10.5 19.4 10.31L20.19 10.05C21 9.79 21.74 10.58 21.43 11.37L19.5 16.28M9 5.8L6.5 3.3V2.75C6.5 2.06 7.06 1.5 7.75 1.5S9 2.06 9 2.75V5.8M12.5 9.3L10 6.8V1.25C10 .56 10.56 0 11.25 0S12.5 .56 12.5 1.25V9.3Z" /></svg>
            </v-col>
            <v-col cols="7">
                <h1>模型にはお手を触れないでください。</h1>
                <p class="ruleeng">Please do not touch the diorama.</p>
                <p class="ruleot">請不要觸摸模型 / 请不要触摸模型</p>
                <p class="ruleot">모델에 손을 대지 마십시오</p>
                <p class="ruleot">กรุณาอย่าแตะรุ่น</p>
                <p class="ruleot">Veuillez ne pas toucher le modèle</p>
                <p class="ruleot">Bitte berühren Sie das Modell nicht</p>
                <p class="ruleot">Noli tangere exemplum</p>
                <p class="ruleot">Ná déan teagmháil leis an múnla le do thoil</p>
                <p class="ruleot">Mai hoʻopā i ke kumu hoʻohālike</p>
                <p class="ruleot">Moet asseblief nie aan die model raak nie</p>
                <p class="ruleot">Tolong jangan sentuh modelnya</p>
                <p class="ruleot">Tafadhali usiguse mfano</p>
            </v-col>
            <v-col cols="1" class="pb-0"></v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    
};
</script>
<style scoped>
    
    .svg{
        height:100%; 
        width:100%;
        fill:#B71C1C;
    }
    h1{
        font-size: 65px;
    }
    .ruleeng{
        font-size: 50px;
        font-weight: bolder;
    }
    .ruleot{
        font-size: 20px;
        font-weight: bolder;
        margin-bottom:0;
    }
</style>