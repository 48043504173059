<template>
    <div>
        <v-app id="inspire">
            <v-card
            class="indigo darken-2 pa-6"
            tile
            >
            <v-row>
                <v-col
                cols="12"
                md="2"
                >
                    <leftMenu/>
                </v-col>
                <v-col
                cols="12"
                md="8"
                >
                    <v-card elevation="12">
                        <h2 class="pa-10 pb-1">資格編集</h2>
                        <hr>
                        <v-container>
                            <v-form 
                            ref="form3"
                            v-model="valid"
                            lazy-validation
                            >
                                <v-btn
                                color="primary"
                                @click="save"
                                >
                                Save
                                </v-btn>
                                <v-simple-table 
                                class="pa-1"
                                fixed-header
                                height="80vh"
                                >
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Id
                                            </th>
                                            <th class="text-left">
                                                enteryear
                                            </th>
                                            <th class="text-left">
                                                name
                                            </th>
                                            <th class="text-left" v-for="(status,index) in gottenCateNum" :key="index">
                                                {{gottenCate[index]}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(member, index) in data" :key="index">
                                            <th scope="row">{{ member[gottenCateNum] }}</th>
                                            <td>{{ member[gottenCateNum+1] }}</td>
                                            <td>{{member[gottenCateNum+2] }}</td>
                                            <td v-for="(status,indexx) in gottenCateNum" :key="indexx">
                                                <v-checkbox
                                                v-model="data[index][indexx]"
                                                ></v-checkbox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-form>
                            
                            
                        </v-container>
                    </v-card>
                </v-col>
                <v-col
                cols="12"
                md="2"
                >
                <rightMenu/>
                </v-col>
            </v-row>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import leftMenu from "./parts/leftMenu";
import rightMenu from "./parts/rightMenu";
import axios from 'axios'
export default {
    components: { leftMenu,rightMenu},
    props: {
        eventid:String,
    },
    data() {
        return {
            drawer:null,
            data:{},
            daynum:0,
            gottenCate:[],
            gottenCateNum:8,
            valid: true,
        };
    },
    created() {
       this.statusCheck();
       this.getCate();
    },
    methods: {
        statusCheck(){
            axios.get('api/fr/event/'+this.eventid+'/member/edit')
                .then((res) => {
                    this.data=res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getCate(){
            axios.get('api/fr/event/'+this.eventid+'/cate')
                .then((res) => {
                    this.gottenCate=res.data;
                    this.gottenCateNum=res.data.length;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        save(){
            axios.post('api/fr/event/'+this.eventid+'/member/edit', {"data":this.data})
                .then(() => {
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};
</script>