<template>
    <div class="contents">
        <v-row>
            <v-col>
                <div class="arrow al al6"></div>
                <div class="arrow bl"></div>
                <div class="arrow al al5"></div>
                <div class="arrow bl"></div>
                <div class="arrow al al4"></div>
                <div class="arrow bl"></div>
                <div class="arrow al al3"></div>
                <div class="arrow bl"></div>
                <div class="arrow al al2"></div>
                <div class="arrow bl"></div>
                <div class="arrow al al1"></div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="disc" style="text-align:center;">
               <h1>立ち止まらずにゆっくりとお進みください</h1> 
               <h2>Please do not stop and proceed slowly.</h2>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    
};
</script>
<style scoped>
    h1{
        font-size: 60px;
    }
    h2{
        font-size: 35px;
    }

    .arrow{
        position: relative;
        margin-top:280px;
        display: inline-block;
        padding: 0 0 0 100px;
        vertical-align: middle;
        text-decoration: none;
        font-size: 100px;
        animation-name: fadeInAnime;/*アニメーションの定義名*/
        animation-duration:4s;/*アニメーション変化時間 ※デフォルト*/
        animation-fill-mode:backwards;/*アニメーションの開始と終了時の状態を指定*/
        opacity:00;
        animation-iteration-count:infinite;
    }
    @keyframes fadeInAnime {
        0%{
        opacity:0;
        }
        20%{
        opacity:100;
        }
        40%{
        opacity:0;
        }
        100%{
        opacity:0;
        }
    }
    .al1{
        animation-delay: 1s;
    }
    .al2{
        animation-delay: 1.1s;
    }
    .al3{
        animation-delay: 1.2s;
    }
    .al4{
        animation-delay: 1.3s;
    }
    .al5{
        animation-delay: 1.4s;
    }
    .al6{
        animation-delay: 1.5s;
    }
    .arrow::before,
    .arrow::after{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        content: "";
        vertical-align: middle;
    }
    .al::before{
    width: 320px;
    height: 320px;
    border-top: 30px solid #1A237E;
    border-right: 30px solid #1A237E;
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
    left:12vw;
    }
    .bl::before{
    width: 320px;
    height: 320px;
    border-top: 30px solid #FFF;
    border-right: 30px solid #FFF;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left:10vw;
    }
    .disc{
        margin-top:230px;
    }
    </style>