<template>
    <div>  
        <v-app id="inspire">
            <div>
                <v-card
                class="indigo darken-2 pa-6"
                tile
                v-if="settingtf"
                >
                    <v-row>
                        <v-col
                        cols="12"
                        md="2"
                        >   
                            <leftMenu/>
                        </v-col>
                        <v-col
                        cols="12"
                        md="10">
                            <v-card
                            elevation="12"
                            >
                                <div class="pa-10">
                                    <h2>表示</h2>
                                    <v-btn
                                        color="indigo darken-2"
                                        dark
                                        block
                                        class="ma-4"
                                        @click="beforedisplaystart"
                                    >
                                        Display
                                    </v-btn>
                                    <hr>
                                    <v-tabs fixed-tabs class="ma-4">
                                        <v-tab href="#tab-1">メインコンテンツ</v-tab>
                                        <v-tab href="#tab-2">サブコンテンツ</v-tab>
                                        <v-tab-item value="tab-1">
                                            <v-row>
                                                <v-col
                                                cols="7"
                                                >
                                                    <v-card class="pa-4 ma-6" elevation="8">
                                                        <draggable tag="div" group="ITEMS" :list="data">
                                                            <v-card v-for="item,indexxx in data" class="pa-1 ma-2" :key="indexxx" light :class="makeColor(item[2])">
                                                                <h4>{{item[0]}} <small>{{item[1]}}</small></h4>
                                                            </v-card>
                                                        </draggable>
                                                        
                                                    </v-card>
                                                </v-col>
                                                <v-col
                                                cols="5"
                                                >
                                                    <v-card class="pa-4 ma-6" elevation="8">
                                                        <draggable tag="div" group="ITEMS" :list="menudata">
                                                            <v-card v-for="item,indexxx in menudata" class="pa-1 ma-2" :key="indexxx" light :class="makeColor(item[2])">
                                                                <h4>{{item[0]}} <small>{{item[1]}}</small></h4>
                                                            </v-card>
                                                        </draggable>
                                                        <v-btn
                                                            color="indigo darken-2"
                                                            dark
                                                            class="mr-4"
                                                            @click="statusCheck"
                                                        >
                                                            RESET Menu
                                                        </v-btn>
                                                        <v-switch
                                                        v-model="music"
                                                        label="音楽再生"
                                                        ></v-switch>
                                                        <v-text-field
                                                            v-model="nexttime"
                                                            label="メインコンテンツ(ms)"
                                                            required
                                                        ></v-text-field>
                                                        <v-text-field
                                                            v-model="eventid"
                                                            label="ローテイベントid"
                                                            required
                                                        ></v-text-field>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-tab-item>
                                        <v-tab-item value="tab-2">
                                            <v-row>
                                                <v-col
                                                cols="7"
                                                >
                                                    <v-card class="pa-4 ma-6" elevation="8">
                                                        <draggable tag="div" group="ITEMS2nd" :list="subdata">
                                                            <v-card v-for="item,indexxx in subdata" class="pa-1 ma-2" :key="indexxx" light :class="makeColor(item[2])">
                                                                <h4>{{item[0]}} <small>{{item[1]}}</small></h4>
                                                            </v-card>
                                                        </draggable>
                                                        
                                                    </v-card>
                                                </v-col>
                                                <v-col
                                                cols="5"
                                                >
                                                    <v-card class="pa-4 ma-6" elevation="8">
                                                        <draggable tag="div" group="ITEMS2nd" :list="submenudata">
                                                            <v-card v-for="item,indexxx in submenudata" class="pa-1 ma-2" :key="indexxx" light :class="makeColor(item[2])">
                                                                <h4>{{item[0]}} <small>{{item[1]}}</small></h4>
                                                            </v-card>
                                                        </draggable>
                                                        <v-btn
                                                            color="indigo darken-2"
                                                            dark
                                                            class="mr-4"
                                                            @click="statusCheck"
                                                        >
                                                            RESET Menu
                                                        </v-btn>
                                                        <v-text-field
                                                            v-model="subnexttime"
                                                            label="サブコンテンツ(ms)"
                                                            required
                                                        ></v-text-field>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-tab-item>
                                    </v-tabs>
                                </div>
                            </v-card>  
                        </v-col>
                    </v-row>
                </v-card>
                <div v-show="!settingtf">
                    <div class="maincontents" style="height:100vh;">
                        <div v-show="displaytf[0]">
                            <video ref="videoPlayer" class="video-js videop"></video>
                        </div>
                        <div v-show="displaytf[7]">
                            <video ref="videoPlayer2" class="video-js videop"></video>
                        </div>
                        <div v-if="displaytf[1]">
                            <img :src="$backurl+'api/media/picture/'+picturenum" class="pictureshow"/>
                        </div>
                        <div v-if="displaytf[2]">
                            <div v-if="displayinfotf[0]">
                                <info1show></info1show>
                            </div>
                            <div v-if="displayinfotf[1]">
                                <info2show></info2show>
                            </div>
                        </div>
                        <div v-if="displaytf[3]">
                            <div v-if="displayforwardtf[0]">
                                <forwardright></forwardright>
                            </div>
                            <div v-if="displayforwardtf[1]">
                                <forwardleft></forwardleft>
                            </div>
                        </div>
                        <div v-if="displaytf[4]">
                            <div v-if="displayvetf[0]">
                                <carsa :activecars="activecars"></carsa>
                            </div>
                            <div v-if="displayvetf[1]">
                                <carsb :activecars="activecars"></carsb>
                            </div>
                        </div>
                        <div v-if="displaytf[5]">
                            <showrote :eventid="eventid" :day="roteday"></showrote>
                        </div>
                        <div v-if="displaytf[6]">
                            <div v-if="displaysigntf[0]">
                                <sign1show></sign1show>
                            </div>
                            <div v-if="displaysigntf[1]">
                                <sign2show></sign2show>
                            </div></div>
                    </div>
                    <div class="subcontents" style="height:15vh;width:99vw">
                        <transition name="fade">
                            <div v-if="subdisplaytf[0]">
                                <statusshow :datas="sidedatas"></statusshow>
                            </div>
                            <div v-if="subdisplaytf[1]">
                                <sellshow :datas="sidedatas"></sellshow>
                            </div>
                            <div v-if="subdisplaytf[2]">
                                <welcomeshow></welcomeshow>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </v-app>
       
            
    </div>
</template>
<script>
import leftMenu from "./parts/leftMenu";
import axios from 'axios'
import draggable from 'vuedraggable';
import statusshow from "./parts/statusshow";
import sellshow from "./parts/sellshow";
import welcomeshow from "./parts/welcomeshow";
import info1show from "./parts/info1";
import info2show from "./parts/info2";
import forwardright from "./parts/forwardright";
import forwardleft from "./parts/forwardleft";
import showrote from "./parts/roteshow";
import sign1show from "./parts/sign1";
import sign2show from "./parts/sign2";
import carsa from "./parts/carsa";
import carsb from "./parts/carsb";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
export default {
    data() {
        return {
            music:false,
            audio: new Audio(require('@/assets/toyama.wav')),
            data:[],
            subdata:[],
            menudata:[],
            submenudata:[],
            settingtf:true,
            displaytf:[false,false,false,false,false,false,false,false],
            subdisplaytf:[false,false,false],
            sidedatas:[['遅れ','2'],'0','データ無し',[false,false,false]],
            nexttime:5000,
            subnexttime:10000,
            subinfo1:[[['平常運行','1'],""],[['平常運行','1'],""],[['平常運行','1'],""],],
            subinfo2:[[['平常運行','1'],""],[['平常運行','1'],""],[['平常運行','1'],""],],
            picturenum:0,
            displayinfotf:[false,false],
            displayforwardtf:[false,false],
            displaysigntf:[false,false],
            displayvetf:[false,false],
            activecars:[],
            eventid:"1",
            roteday:"1"
        };
    },
    components: { 'draggable':draggable,leftMenu,statusshow,sellshow,welcomeshow,info1show,info2show,forwardleft,forwardright,carsa,carsb,showrote,sign1show,sign2show},
    mounted() {
        this.statusCheck();
    },
    methods: {
        beforedisplaystart(){
            this.data.push(["","","",""])
            this.subdata.push(["","","",""])
            this.displaystart()
            this.subdisplaystart()
            this.subupdate()
            if(this.music==true){
                this.audio.currentTime = 0
                this.audio.loop = true;
                this.audio.play()
            }
        },
        displaystart(){
            this.settingtf=false
            var count=0
            if(this.data.length-1==0){
                console.warn('error')
                return false
            }else{
                this.makeclass(this.data[0][2],this.data[0][3],count)
            }
        },
        subdisplaystart(){
            var count=0
            if(this.data.length-1==0){
                this.data.push(["ウェルカムメッセージ","来場者をもてなします","2","0"])
            }
            this.makesubclass(this.subdata[0][2],this.subdata[0][3],count)
        },
        displaynext(dataid,id,count){
            if(this.data.length-1==count){
                this.displaystart()
            }else{
                this.makeclass(dataid,id,count)
            }
        },
        subdisplaynext(dataid,id,count){
            if(this.subdata.length-1==count){
                this.subdisplaystart()
            }else{
                this.makesubclass(dataid,id,count)
            }
        },
        makeclass(dataid,id,count){
            if(dataid=="0"){
                if(id=="1"){
                    this.makemovie(id,count)
                }else if(id=="2"){
                    this.makemovie2(id,count)
                }
            }else if(dataid=="1"){
                this.makephoto(id,count)
            }else if(dataid=="2"){
                this.makeinfo(id,count)
            }else if(dataid=="3"){
                this.makeroute(id,count)
            }else if(dataid=="4"){
                this.makevehicle(id,count)
            }else if(dataid=="5"){
                this.makerote(id,count)
            }else if(dataid=="6"){
                this.makesign(id,count)
            }
        },
        makesubclass(dataid,id,count){
            if(dataid=="0"){
                this.makeopinfo(id,count)
            }else if(dataid=="1"){
                this.makesellinfo(id,count)
            }else if(dataid=="2"){
                this.makewelcome(id,count)
            }
        },
        displaydatareset(){
            this.displaytf=[]
            for(var i=0;i<8;i++){
                this.displaytf.push(false)
            }
        },
        subdisplaydatareset(){
            this.subdisplaytf=[]
            for(var i=0;i<3;i++){
                this.subdisplaytf.push(false)
            }
        },
        subupdate(){
            axios.get('api/home/datainfo/1')
                .then((res) => {
                    this.subinfo1=res.data
                    axios.get('api/home/datainfo/2')
                        .then((res) => {
                            this.subinfo2=res.data
                            setTimeout(this.subupdate,60000)
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        makeopinfo(id,count){
            this.subdisplaydatareset()
            this.subdisplaytf[0]=true
            var showtf=[false,false,false]
            showtf[this.subinfo1[id][0][1]-1]=true
            this.sidedatas=[this.subinfo1[id][0],id,this.subinfo1[id][1],showtf]
            count=count+1
            setTimeout(this.subdisplaynext,this.subnexttime,this.subdata[count][2],this.subdata[count][3],count)
        },
        makesellinfo(id,count){
            this.subdisplaydatareset()
            this.subdisplaytf[1]=true
            var showtf=[false,false,false]
            showtf[this.subinfo2[id][0][1]-1]=true
            this.sidedatas=[this.subinfo2[id][0],id,this.subinfo2[id][1],showtf]
            count=count+1
            setTimeout(this.subdisplaynext,this.subnexttime,this.subdata[count][2],this.subdata[count][3],count)
        },
        makewelcome(id,count){
            this.subdisplaydatareset()
            this.subdisplaytf[2]=true
            count=count+1
            id=id+1
            setTimeout(this.subdisplaynext,this.subnexttime,this.subdata[count][2],this.subdata[count][3],count)        
        },
        makemovie(id,count){
            this.displaydatareset()
            this.displaytf[0]=true
            count=count+1
            if(this.player==null){
                var videoOptions={
                    controls: false,
                    fluid: true,
                    autoplay:true,
                    lop:false,
                    sources: [
                        {
                            src: this.$backurl+"api/media/movie/"+id,
                            type: "video/mp4"
                        }
                    ]
                }
                this.player = videojs(this.$refs.videoPlayer,videoOptions, function onPlayerReady() {
                    console.log('onPlayerReady', this);
                })
                this.player.on(['play'], () => {
                    var time=this.player.cache_.duration
                    var timenext=time*1000
                    console.log(timenext)
                    setTimeout(this.displaynext,timenext,this.data[count][2],this.data[count][3],count)
                });
            }else{
                this.player.play()
            }
        },
        makemovie2(id,count){
            this.displaydatareset()
            this.displaytf[7]=true
            count=count+1
            if(this.player2==null){
                var videoOptions2={
                    controls: false,
                    fluid: true,
                    autoplay:true,
                    loop:false,
                    sources: [
                        {
                            src: this.$backurl+"api/media/movie/"+id,
                            type: "video/mp4"
                        }
                    ]
                }
                this.player2 = videojs(this.$refs.videoPlayer2,videoOptions2, function onPlayerReady() {
                    console.log('onPlayerReady', this);
                })
                this.player2.on(['play'], () => {
                    var time=this.player2.cache_.duration
                    var timenext=time*1000
                    console.log(timenext)
                    setTimeout(this.displaynext,timenext,this.data[count][2],this.data[count][3],count)
                });
            }else{
                this.player2.play()
            }
        },
        makephoto(id,count){
            this.displaydatareset()
            this.displaytf[1]=true
            this.picturenum=id
            id=id+1
            count=count+1
            setTimeout(this.displaynext,this.nexttime,this.data[count][2],this.data[count][3],count)
        },
        makeinfo(id,count){
            this.displaydatareset()
            this.displaytf[2]=true
            this.displayinfotf=[false,false]
            this.displayinfotf[id-1]=true
            id=id+1
            count=count+1
            setTimeout(this.displaynext,this.nexttime,this.data[count][2],this.data[count][3],count)
        },
        makeroute(id,count){
            this.displaydatareset()
            this.displaytf[3]=true
            this.displayforwardtf=[false,false]
            this.displayforwardtf[id-1]=true
            id=id+1
            count=count+1
            setTimeout(this.displaynext,this.nexttime,this.data[count][2],this.data[count][3],count)
        },
        makevehicle(id,count){
            this.displaydatareset()
            this.displaytf[4]=true
            this.displayvetf=[false,false]
            if(id=='1'){
                this.displayvetf[0]=true;
                this.name='syonan'
            }else if(id=='2'){
                this.displayvetf[1]=true
                this.name='shikoku'
            }
            axios.get('api/vm/cars/active/'+this.name)
                .then((res) => {
                    this.activecars=res.data
                })
                .catch(error => {
                    console.log(error)
                });
            id=id+1
            count=count+1
            setTimeout(this.displaynext,this.nexttime,this.data[count][2],this.data[count][3],count)
        },
        makerote(id,count){
            this.displaydatareset()
            this.roteday=id
            this.displaytf[5]=true
            count=count+1
            setTimeout(this.displaynext,this.nexttime,this.data[count][2],this.data[count][3],count)
        },
        makesign(id,count){
            this.displaydatareset()
            this.displaytf[6]=true
            this.displaysigntf=[false,false]
            this.displaysigntf[id-1]=true
            id=id+1
            count=count+1
            setTimeout(this.displaynext,this.nexttime,this.data[count][2],this.data[count][3],count)
        },
        statusCheck(){
            axios.get('api/home/displayinfo/1')
                .then((res) => {
                    this.menudata=res.data
                })
                .catch(error => {
                    console.log(error);
                });
            axios.get('api/home/displayinfo/2')
                .then((res) => {
                    this.submenudata=res.data
                })
                .catch(error => {
                    console.log(error);
                });
        },
        makeColor(type){
            if (type=="0") {
                return "red lighten-2";
            }else if (type=="1"){
                return "pink lighten-2";
            }else if (type=="2"){
                return "purple lighten-2";
            }else if (type=="3"){
                return "deep-purple lighten-2";
            }else if (type=="4"){
                return "indigo lighten-2";
            }else if (type=="5"){
                return "blue lighten-2";
            }else{
                return "cyan lighten-2";
            }
        },
    }
};
</script>
<style scoped>
    .maincontents{
        position: relative;
    }
    .pictureshow{
        height:100vh;
        width:100vw;
        object-fit:cover;
    }
    .subcontents{
        position: absolute;
        top: 82vh;
        left: 0;
    }
    .videop{
        height:100vh;
    }
</style>