<template>
    <div>
        <v-card
        elevation="12"
        
        >
        <v-navigation-drawer
            floating
            permanent
        > 
            <div
            class="pa-6"
            >
                <h1>Rotainer</h1> 
                <p><strong>ローテ作成システム</strong></p>
            </div>
            
            <v-list
            dense
            rounded
            >
            <v-list-item
                v-for="item in items"
                :key="item.title"
                link
            >
                <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <router-link v-bind:to="{name:item.link}">
                        <strong><v-list-item-title class="link">{{ item.title }}</v-list-item-title></strong>
                    </router-link>
                
                </v-list-item-content>
            </v-list-item>
            </v-list>
        </v-navigation-drawer>
        </v-card>
    </div>
</template>

<script>
export default {
   data() {
        return {
            items: [
                { title: 'Home', icon: 'home',link:'fesrotainer.home' },
                { title: 'Event add', icon: 'mdi-plus',link:'fesrotainer.eventsadd'  },
                { title: 'Member list', icon: 'mdi-list-box',link:'fesrotainer.memberslist'  },
            ],
        };
    },
    methods:{
    }
};
</script>
<style>
    .link{
        color:black;
        text-decoration: none;
    }
</style>